import * as actions from './actionTypes';

export const getOffice = (successHandler, errorHandler) => {
    return {
        type: actions.GET_OFFICE,
        successHandler,
        errorHandler,
    }
};

export const getCurrency = (successHandler, errorHandler) => {
    return {
        type: actions.GET_CURRENCY,
        successHandler,
        errorHandler,
    }
};

export const getGlAccountByQueryParam = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.GET_GL_ACCOUNT_BY_QUERYPARAM,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const getCharges = (successHandler, errorHandler) => {
    return {
        type: actions.GET_CHARGES,
        successHandler,
        errorHandler,
    }
};

export const getChargesWithQueryParam = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.GET_CHARGES_WITH_QUERYPARAM,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const deleteCharges = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.DELETE_CHARGES,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const submitCharges = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_CHARGES,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getChargesEditWithQueryParam = (queryparam,data,successHandler, errorHandler) => {
    return {
        type: actions.GET_DETAIL_EDIT_CHARGES,
        queryparam:queryparam,
        data:data,
        successHandler,
        errorHandler,
    }
};

export const editCharges = (id,payload,successHandler, errorHandler) => {
    return {
        type: actions.EDIT_CHARGES,
        payload:payload,
        id:id,
        successHandler,
        errorHandler,
    }
};
export const getValueRunReports = (queryparam,successHandler, errorHandler) => {
    return {
        type: actions.GET_VALUE_RUNREPORT,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};

export const submitBatches = (payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_BATCHES,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const getAllCodeTrxNonCash = (successHandler, errorHandler) => {
    return {
        type: actions.GET_ALL_CODE_TRX_NON_CASH,
        successHandler,
        errorHandler,
    }
};

export const getProductGroupPPOB = (successHandler, errorHandler) => {
    return {
        type: actions.GET_PRODUCT_GROUP_PPOB,
        successHandler,
        errorHandler,
    }
};

export const getFundsWithParam = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_FUNDS_WITH_PARAM,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getCurrencyWithParam = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_CURRENCY_WITH_PARAM,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getAllCodeTrxCash = (successHandler, errorHandler) => {
    return {
        type: actions.GET_ALL_CODE_TRX_CASH,
        successHandler,
        errorHandler,
    }
};

export const getDataDenomination = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_DENOMINATION,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataFinancialActivity = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_FINANCIALACTIVITYACCOUNT,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddDenomination = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_DENOMiNATION,
        payload:payload,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitEditDenomination = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_DENOMiNATION,
        payload:payload,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteDenomination = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_DENOMiNATION,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataMakerCheker = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_MAKERCHEKER,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitMakerChekerMultiple = (param,payload,listid,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_MAKER_CHEKER_MULTIPLE,
        param:param,
        listid:listid,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteMakerCheker = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_MAKERCHEKER,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataAudits = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_AUDITS,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getDataTellerDashboard = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_TELLER_DASHBOARD,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitAddTellerOperation = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ADD_TELLER_OPERATION,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitEditTellerOperation = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_EDIT_TELLER_OPERATION,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitDeleteTellerOperation = (param,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_DELETE_TELLER_OPERATION,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const submitSettleTeller = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_SETTLE_TELLER,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};

export const submitAllocateTeller = (param,payload,successHandler, errorHandler) => {
    return {
        type: actions.SUBMIT_ALLOCATE_TELLER,
        param:param,
        payload:payload,
        successHandler,
        errorHandler,
    }
};


export const getQuickSearch = (param,successHandler, errorHandler) => {
    return {
        type: actions.GET_QUICK_SEARCH,
        param:param,
        successHandler,
        errorHandler,
    }
};

export const getRunreportCheck = (encodenamafile,namafile,successHandler, errorHandler) => {
    return {
        type: actions.GET_RUN_REPORT_CHECK,
        encodenamafile:encodenamafile,
        namafile:namafile,
        successHandler,
        errorHandler,
    }
};

export const getDataTellerDashboardParam = (queryparam,actiontype,successHandler, errorHandler) => {
    return {
        type: actions.GET_DATA_TELLER_DASHBOARD_PARAM,
        actiontype:actiontype,
        queryparam:queryparam,
        successHandler,
        errorHandler,
    }
};
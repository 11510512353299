import axios        from '../../Axios-Simpool';
import {put}        from 'redux-saga/effects';
import * as actions from '../actions';
import {
    clientUrl, clientTemplateUrl,
    checkDuplicateUrl, clientImageUrl,
    clientDocumentUrl,
    createMemberNew,runreports,clientAccountUrl,clientTemplateCloseUrl,closeMemberURL
    ,parsingNIKUrl,baseClientJobURL,basedClienApproval,memberCommandURL,clientTemplateParamUrl,
    memberEntityUrl,memberPersonUrl,getclientDataWithParamUrl,updateEmployeeInformationDataImportURL,updateClientSimpleURL,
    memberPagingPersonUrl,
    baseTransferclientaccounthistoryURL
}                   from '../../shared/apiUrl';

import {NumMonthToNameMonthInEnglish, toLogout,msgErrorHandler} from '../../shared/maskFunc'
import { getErrorJsonData } from '../../shared/utility';

export function* initMemberListSaga(action) {
    
    // console.log("url ",clientUrl + action.query);
    try {
        const response = yield axios.get(clientUrl + action.query).then(res => res.data);
        // console.log("response member ",response);
        yield put(actions.setMemberData(response))

    } catch (error) {
        toLogout(error);
        yield put(actions.fetchMemberFailed());
    }
}

export function* initMemberTemplateSaga(action) {
    try {
        const response = yield axios.get(clientTemplateUrl, {
            params: {
                staffInSelectedOfficeOnly: "true"
            }
        }).then(response => response.data)
            .catch(error => console.log(error.response.data));
            
        yield put(actions.fetchMemberTemplate(response));
    } catch (error) {
        toLogout(error);
        yield put(actions.fetchMemberTemplateFailed());
    }
}

export function* checkDuplicateMemberSaga(action) {
    const locale = yield localStorage.getItem('simpool-lng');
    var dt = '';
    var dob = '';
    console.log("DOBB ",action.payload.dateOfBirth);
    var local = 'en';
    try{
        dt = new Date(action.payload.dateOfBirth);
        // dt = action.payload.dateOfBirth;
        console.log("DOBBBBBB ",dt.getMonth());
        dob = dt.getDate()+" "+NumMonthToNameMonthInEnglish (dt.getMonth()+1)+" "+dt.getFullYear();
    }catch(error){
        // dt = new Date(action.payload.dateOfBirth);
        // console.log("DOBBCC ",dt.getMonth());
        dob = action.payload.dateOfBirth;
        local = 'in';
    }
    try {
        const response = yield axios.get(checkDuplicateUrl, {
            params: {
                fullname: action.payload.fullname,
                dateOfBirth: dob,
                identityNumber: action.payload.identityNumber,
                isDuplicate: "false",
                legalForm: action.payload.legalFormId,
                limit: "1000",
                typeOfIdentityId: action.payload.typeOfIdentityId,
                locale: local
            }
        })
            .then(response => response.data)
            .catch(error => console.log(error.response.data));
        // yield put(actions.checkDuplicateMemberResult(response));
        action.checkDuplicateResult(response);
    } catch (error) {
        console.log(error);
        action.errorHandler(error);
    }
}

export function* memberAddSaga(action) {
    yield put(actions.memberAddStart());
    try {
        const response = yield axios.post(createMemberNew, action.payload).then(response => response.data);
        
        if (response.clientId !== null) {
            yield put(actions.memberAddSuccess());
            action.uploadFiles(response.clientId);
        }
    } catch (error) {
        yield put(actions.memberAddFailed());
        action.errorHandler(msgErrorHandler(error));
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(errMessages.join('\n'));
    }
}

export function* memberAddImageSaga(action) {
    yield put(actions.memberAddImageStart());
    try {
        const response = yield axios.post(clientImageUrl(action.id),
            action.image).then(response => response.data);
        yield put(actions.memberAddImageSuccess());
        action.successHandler();
    } catch (error) {
        yield put(actions.memberAddImageFailed());
        if (error.data.status === 404){
            action.errorHandler(error.data.message);
        } else {
            const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
            action.errorHandler(errMessages);
        }
    }
}

export function* memberAddDocumentSaga(action) {
    yield put(actions.memberAddDocumentStart());
    try {
        const response = yield axios.post(clientDocumentUrl(action.id), action.document)
            .then(response => response.data);
        if (response.resourceId !== null) {
            yield put(actions.memberAddDocumentSuccess());
            action.successHandler();
        }
    } catch (error) {
        yield put(actions.memberAddDocumentFailed());
        console.log('error', error);
        if (error.data.status === 404){
            action.errorHandler(error.data.message);
        } else {
            const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
            action.errorHandler(errMessages);
        }
    }
}

export function* reportsMemberSaga(action) {
    try {
        // console.log("masuk api RM");
        let urlrep = runreports+"/"+action.param;
        let resType = '';
        if(action.typefile === 'application/pdf' || action.typefile === 'application/vnd.ms-excel' || action.typefile === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'){
            resType = 'arraybuffer'
        }
        const response = yield axios.get(urlrep, {
            //arraybuffer
            responseType: resType,
            headers: {
                Accept: action.typefile,
            },
        }).then(response => response.data)
        // console.log("masuk api RM 2, ",response);
            // .catch(error => console.log(error.response.data));
            action.successHandler(response);
    } catch (error) {
        // toLogout(error);
        let obj = new Object();
        obj.paramreport = action.param;
        const errMessages = msgErrorHandler(error,obj);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        
        action.errorHandler(errMessages);
    }
}

export function* initMemberTemplateSaga2(action) {
    try {
        // console.log("initMemberTemplate start");
        const response = yield axios.get(clientTemplateUrl, {
            params: {
                staffInSelectedOfficeOnly: "true"
            }
        }).then(response => response.data) ;
            // console.log("initMemberTemplate response "+JSON.stringify(response));
            action.successHandler(response);
        // yield put(actions.fetchMemberTemplate(response));
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
        // yield put(actions.fetchMemberTemplateFailed());
    }
}

export function* initGetSavingsByClientIDSaga(action) {
    
    // console.log("url ",clientUrl + action.query);
    try {
        const response = yield axios.get(clientAccountUrl(action.clientid)+'/savings').then(res => res.data);
        // console.log("response member ",response);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getTemplateClientCloseSaga(action) {
    
    // console.log("url ",clientUrl + action.query);
    try {
        const response = yield axios.get(clientTemplateCloseUrl).then(res => res.data);
        // console.log("response member ",response);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getParsingNIKSaga(action) {
    try {
        const response = yield axios.post(parsingNIKUrl,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* CloseMemberSaga(action) {
    try {
        // let url = savingsAccountUrl+"?sqlSearch="+action.accountnumber;
        // console.log('GetListSavingsByAccountNumberSaga ',url)
        const response = yield axios.post(closeMemberURL(action.clientid),action.payload).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        const errMessages = [msgErrorHandler(error)];
        action.errorHandler(errMessages);
    }
}

export function* submitMemberCommandPostSaga(action) {
    try {
        const response = yield axios.post(memberCommandURL(action.id,action.command),action.payload).then(response => response.data);
        action.successHandler(response);
    }catch (error) {
        toLogout(error);
        // const errMessages = [msgErrorHandler(error)];
        // action.errorHandler(errMessages);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}
export function* initMemberList2Saga(action) {
    
    // console.log("url ",clientUrl + action.query);
    try {
        const response = yield axios.get(clientUrl + action.query).then(res => res.data);
        // console.log("response member ",response);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataClientJobsSaga(action) {
    try {
        const response = yield axios.get(baseClientJobURL(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddClientJobsSaga(action) {
    try {
        const response = yield axios.post(baseClientJobURL(''),action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(error);
    }
}

export function* submitEditClientJobsSaga(action) {
    try {
        const response = yield axios.put(baseClientJobURL(action.param),action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(error);
    }
}

export function* submitDeleteClientJobsSaga(action) {
    try {
        const response = yield axios.delete(baseClientJobURL(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataClientApprovalSaga(action) {
    try {
        const response = yield axios.get(basedClienApproval(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitClientApprovalSaga(action) {
    try {
        const response = yield axios.post(basedClienApproval(action.param),action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitClientApprovalMultipleSaga(action) {
    try {
        const response = yield axios.post(basedClienApproval(action.param),action.payload).then(response => response.data);
        action.successHandler(response,action.arrids);
    } catch (error) {
        // toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}), action.arrids);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        // action.errorHandler(error,action.arrids);
    }
}

export function* getDataClientApprovalListActivationSaga(action) {
    try {
        const response = yield axios.get(basedClienApproval(action.param)).then(response => response.data);
        action.successHandler(response,action.actiontype);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataMemberTemplateSaga(action) {
    try {
        const response = yield axios.get(clientTemplateParamUrl(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getCheckDuplicateMemberSaga(action) {
    try {
        const response = yield axios.get(checkDuplicateUrl+action.param).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddMemberEntitySaga(action) {
    try {
        const response = yield axios.post(clientUrl,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        // toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* getDataEntityMemberSaga(action) {
    try {
        const response = yield axios.get(memberEntityUrl+action.param).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* getDataPersonMemberSaga(action) {
    try {
        const response = yield axios.get(memberPersonUrl+action.param).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* getDataMemberWithParamSaga(action) {
    try {
        const response = yield axios.get(getclientDataWithParamUrl(action.param)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitEditMemberEntitySaga(action) {
    try {
        const response = yield axios.put(clientUrl+'/'+action.id,action.payload).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitUpdateEmployeeInformationSaga(action) {
    try {
        const response = yield axios.post(updateEmployeeInformationDataImportURL("/"+action.id),action.payload).then(response => response.data);
        action.successHandler(response,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    }
}

export function* submitDataImportUpdateClientSaga(action) {
    try {
        const response = yield axios.post(updateClientSimpleURL, action.payload).then(response => response.data);
        action.successCallback(response,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    } catch (error) {
        // console.log('submitDataImportUpdateClientSaga '+error);
        toLogout(error);
        const errMessages = msgErrorHandler(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorCallback(errMessages,action.to,action.row,action.listdata,action.listerr,action.listdone,action.extractresult,action.dispatch);
    }
}

export function* getDataMemberParamSaga(action) {
    try {
        // console.log('memberPagingPersonUrl ',memberPagingPersonUrl(action.param));
        const response = yield axios.get(memberPagingPersonUrl(action.param)).then(response => response.data);
        action.successHandler(response,action.actiontype);
    } catch (error) {
        // toLogout(error);
        // console.log('getDataMemberParamSaga ',error);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* getTransferClientHistorySaga(action) {
    let url = action.param.url ? action.param.url:'';
    try {
        const response = yield axios.get(baseTransferclientaccounthistoryURL(url)).then(response => response.data);
        action.successHandler(response);
    } catch (error) {
        // toLogout(error);
        action.errorHandler(getErrorJsonData({errors:error}));
    }
}

export function* submitMemberCommandPostMultipleSaga(action) {
    try {
        const response = yield axios.post(memberCommandURL(action.id,action.command),action.payload).then(response => response.data);
        action.successHandler(response,action.listid,action.command);
    }catch (error) {
        toLogout(error);
        // const errMessages = [msgErrorHandler(error)];
        // action.errorHandler(errMessages);
        action.errorHandler(getErrorJsonData({errors:error}),action.listid,action.command);
    }
}
export const memberDetail = ['READ_CLIENT', 'READ_ClientSummary', 'READ_CLIENTIMAGE', 'READ_DOCUMENT'];
export const addMember = ['READ_CLIENT', 'ACTIVATE_CLIENT', 'CREATE_REGISTER_FROM_WEB'];
export const updateMember = ['READ_CLIENT', 'UPDATE_CLIENT'];
export const addSavingsInMember = ['READ_CLIENT', 'READ_SAVINGSACCOUNT', 'READ_SAVINGSPRODUCT', 'CREATE_SAVINGSACCOUNT', 'ACTIVATE_SAVINGSACCOUNT', 'APPROVE_SAVINGSACCOUNT'];
export const addTermSavingsInMember = ['READ_CLIENT', 'READ_FIXEDDEPOSITACCOUNT', 'CREATE_FIXED_DEPOSIT_ACCOUNT_SIMPOOLV3'];
export const addFinancingInMember = ['READ_CLIENT', 'READ_LOANPRODUCT', 'READ_LOAN', 'CREATE_LOAN'];
export const detailSavings = ['READ_SAVINGSACCOUNT'];
export const generateQRSavings = ['READ_EMVQR_CODE', 'READ_CLIENTIMAGE'];
export const depositoSavings = ['READ_CORETRANSACTIONCODE', 'READ_CHARGE', 'CREATE_DEPOSITTRANSACTIONBACKOFFICE'];
export const termsavingsDetail = ['READ_FIXEDDEPOSITACCOUNT'];
export const paymentFinancing = ['CREATE_SAVINGSTOLOAN_FORBACKOFFICER'];
export const changePasswordMobileUser = ['CREATE_CHANGE_PASSWORD_EXTERNAL_USER'];
export const resendPasswordMobileUser = ['CREATE_RESET_PASSWORD_EXTERNAL_USER'];
export const deleteMobileUser = ['DELETE_EXTERNALUSER'];
export const unlockMobileUser = ['CREATE_UNLOCK_EXTERNAL_USER', 'CONFIRM_CHANGE_PASSWORD_EXTERNAL_USER'];
export const editMobileUser = ['UPDATE_EXTERNALUSER'];
export const resetPinMobileUser = ['RESET_PIN'];
export const rejectLoan = ['REJECT_LOANAPPROVAL'];
export const approveLoan = ['APPROVE_LOANAPPROVAL'];
export const disburseToSavingsLoan = ['DISBURSETOSAVINGS_LOAN'];
export const transHistoryAndTopUpToken = ['READ_TOKEN_TRANSACTION_HISTORY', 'READ_TOP_UP'];
export const requestToken = ['CREATE_TOP_UP'];
export const financingDetail = ['READ_LOAN', 'READ_SAVINGSACCOUNT'];
export const detailMobileUser = ['READ_EXTERNALUSER'];
export const closeClient = ['CLOSE_CLIENT'];
export const closeSavings = ['CLOSE_SAVINGSACCOUNT'];
export const maintenanceSavings = ['READ_SAVINGSACCOUNT', 'MAINTENANCE_SAVINGS', 'UPDATE_SAVINGSACCOUNT'];
export const viewUserProfile = ['READ_USER'];
export const changePasswordUser = ['CREATE_CHANGE_PASSWORD', 'CONFIRM_CHANGE_PASSWORD', 'READ_PASSWORD_VALIDATION'];
export const addCoa = ['READ_GLACCOUNT', 'CREATE_GLACCOUNT'];
export const editCoa = ['READ_GLACCOUNT', 'UPDATE_GLACCOUNT'];
export const deleteCoa = ['DELETE_GLACCOUNT'];
export const addSettingFrequentPosting = ['READ_ACCOUNTINGRULE', 'CREATE_ACCOUNTINGRULE'];
export const editSettingFrequentPosting = ['READ_ACCOUNTINGRULE', 'UPDATE_ACCOUNTINGRULE'];
export const deleteSettingFrequentPosting = ['DELETE_ACCOUNTINGRULE'];
export const termSavingsPrematureClose_permission = ['READ_GLACCOUNT', 'PREMATURECLOSE_FIXEDDEPOSITACCOUNT'];
export const termSavingsMaintenance_permission = ['READ_GLACCOUNT', 'MAINTENANCE_DEPOSIT'];
export const termSavingsAdjusment_permission = ['READ_GLACCOUNT', 'ADJUSTMENT_DEPOSIT'];
export const receipt_savings = ['READ_ACCOUNTTRANSFER', 'READ_SAVINGSACCOUNT'];
export const DetailInternalUser_Permission = ['READ_USER'];
export const AddInternalUser_Permission = ['READ_USER', 'CREATE_USER'];
export const EditInternalUser_Permission = ['READ_USER', 'UPDATE_USER'];
export const DeleteInternalUser_Permission = ['DELETE_USER'];
export const UnlockInternalUser_Permission = ['CREATE_UNLOCK_INTERNAL_USER', 'CONFIRM_UNLOCK_INTERNAL_USER'];
export const ChangePasswordInternalUser_Permission = ['CREATE_CHANGE_PASSWORD_INTERNAL_USER', 'CONFIRM_CHANGE_PASSWORD_INTERNAL_USER'];
export const ResetMobileUser_Permission = ['READ_EXTERNALUSER', 'CREATE_RESET_MOBILE_DEVICE', 'CONFIRM_RESET_MOBILE_DEVICE'];
export const chartGender_permission = ['READ_GenderTrend'];
export const chartAge_permission = ['READ_AgeTrend'];
export const chartProvince_permission = ['READ_ProviceTrend'];
export const chartNewMember_permission = ['READ_ClientTrendByMonth'];
export const chartFinancingDisbursement_permission = ['READ_LoanTrendByMonth'];
export const mapsDashboard_permission = ['READ_DASHBOARD_GEOGRAPHY_CITY'];
export const totalMembersDashboard_permission = ['READ_DASHBOARD_TOTAL', 'READ_DASHBOARD_CLIENT_MONTH'];
export const totalCoopDashboard_permission = ['READ_DASHBOARD_TOTAL', 'READ_DASHBOARD_CLIENT_TENANT', 'READ_DASHBOARD_CITY'];
export const dashboardTotalApex_permission = ['READ_DASHBOARD_TOTAL'];
export const dashboardSynchronus_permission = ['READ_DASHBOARD_SYNCHRONOUS'];
export const savingEstatement_permission = ['READ_Client Saving Transactions'];
export const dashboardisActiveUser_permission = ['READ_DASHBOARD_MOBILE_USER_MONTH'];
export const chargeAdd_permission = ['READ_CHARGE', 'CREATE_CHARGE'];
export const chargeEdit_permission = ['READ_CHARGE', 'UPDATE_CHARGE'];
export const chargeDelete_permission = ['DELETE_CHARGE'];
export const DetailQrMerchant = ['READ_MITRA_MERCHANT', 'READ_QR_MERCHANT', 'READ_merchantsQr'];
export const loanMaintenance_permission = ['MAINTENANCE_LOAN'];
export const loan_WaiveInteres_permission = ['WAIVEINTERESTPORTION_LOAN'];
export const loan_Writeoff_permission = ['WRITEOFF_LOAN'];
export const loan_Prepay_permission = ['PREPAY_LOAN'];
export const loan_AgreementLetter_permission = ['READ_AGREEMENT_LETTER', 'READ_LOAN'];
export const viewJournalEntries_Permission = ['READ_JOURNALENTRY', 'READ_GLACCOUNT', 'READ_OFFICE'];
export const printJournalEntries_Permission = ['READ_Search Journal Entries Report'];
export const detailAutoSweep_Permission = ['READ_AUTOSWEEP'];
export const addAutoSweep_Permission = ['READ_AUTOSWEEP', 'CREATE_AUTOSWEEP'];
export const editAutoSweep_Permission = ['READ_AUTOSWEEP', 'UPDATE_AUTOSWEEP'];
export const deleteAutoSweep_Permission = ['DELETE_AUTOSWEEP'];
export const createAutoSweepBulk_Permission = ['READ_AUTOSWEEPBULKVALIDATION', 'CREATE_AUTOSWEEPBULKVALIDATION'];
export const editAutoSweepBulk_Permission = ['READ_AUTOSWEEPGROUP', 'UPDATE_AUTOSWEEPBULKVALIDATION'];

export const detailAutoSweepGroup_Permission = ['READ_AUTOSWEEPGROUP'];
export const addAutoSweepGroup_Permission = ['READ_AUTOSWEEPGROUP', 'CREATE_AUTOSWEEPGROUP'];
export const editAutoSweepGroup_Permission = ['READ_AUTOSWEEPGROUP', 'UPDATE_AUTOSWEEPGROUP'];
export const deleteAutoSweepGroup_Permission = ['DELETE_AUTOSWEEPGROUP'];
export const undoapproval_permission = ['APPROVALUNDO_LOAN'];
export const paySavingsCharge_Permission = ['PAY_SAVINGSACCOUNTCHARGE', 'READ_SAVINGSACCOUNTCHARGE'];
export const waiveSavingsCharge_Permission = ['WAIVE_SAVINGSACCOUNTCHARGE'];
export const nonActiveSavingsCharge_Permission = ['INACTIVATE_SAVINGSACCOUNTCHARGE'];

export const updateImageMember_permission = ['CREATE_CLIENTIMAGE'];
export const deleteImageMember_permission = ['DELETE_CLIENTIMAGE'];
export const readDocMember_permission = ['READ_DOCUMENT'];
export const addDocMember_permission = ['CREATE_DOCUMENT'];
export const deleteDocMember_permission = ['DELETE_DOCUMENT'];
export const reactivatedMember_permission = ['REACTIVATE_CLIENT'];
export const activatedMember_permission = ['ACTIVATE_CLIENT'];
export const cancelDepositBiller_permission = ['CANCEL_DEPOSIT_BILLER'];
export const addDepositBiller_permission = ['READ_DEPOSIT_BILLER_SERVICE', 'CREATE_DEPOSIT_BILLER'];
export const addDepositBillerService_permission = ['READ_DEPOSIT_BILLER_SERVICE', 'CREATE_DEPOSIT_BILLER_SERVICE'];
export const editDepositBillerService_permission = ['READ_DEPOSIT_BILLER_SERVICE', 'UPDATE_DEPOSIT_BILLER_SERVICE'];
export const deleteDepositBillerService_permission = ['DELETE_DEPOSIT_BILLER_SERVICE'];

export const AddPromotionManagement_Permission = ['READ_PROMOTION', 'CREATE_PROMOTION'];
export const EditPromotionManagement_Permission = ['READ_PROMOTION', 'UPDATE_PROMOTION'];
export const DeletePromotionManagement_Permission = ['DELETE_PROMOTION'];

export const AddAgentGroup_Permission = ['READ_AGENT_GROUP', 'CREATE_AGENT_GROUP'];
export const EditAgentGroup_Permission = ['READ_AGENT_GROUP', 'UPDATE_AGENT_GROUP'];
export const DeleteAgentGroup_Permission = ['DELETE_AGENT_GROUP'];
export const ListAgentGroupComission_Permission = ['READ_AGENT_GROUP_COMMISSION'];
export const AddAgentGroupComission_Permission = ['READ_AGENT_GROUP_COMMISSION', 'CREATE_AGENT_GROUP_COMMISSION'];
export const DeleteAgentGroupComission_Permission = ['DELETE_AGENT_GROUP_COMMISSION'];
export const EditAgentGroupComission_Permission = ['UPDATE_AGENT_GROUP_COMMISSION'];

export const DetailMenuAgent = ['READ_AGENT', 'READ_AGENT_GROUP_COMMISSION'];
export const ListSpecialComissionMenuAgent = ['READ_AGENT_SPECIAL_COMMISSION'];
export const AddSpecialComissionMenuAgent = ['READ_AGENT_GROUP_COMMISSION', 'CREATE_AGENT_SPECIAL_COMMISSION'];
export const EditSpecialComissionMenuAgent = ['READ_AGENT_GROUP_COMMISSION', 'UPDATE_AGENT_SPECIAL_COMMISSION'];
export const DeleteSpecialComissionMenuAgent = ['DELETE_AGENT_SPECIAL_COMMISSION'];
export const AddAgent_Permission = ['READ_AGENT', 'CREATE_AGENT'];
export const EditAgent_Permission = ['READ_AGENT', 'UPDATE_AGENT'];
export const DeleteAgent_Permission = ['DELETE_AGENT'];
export const EditFinancingCollectionSheet_Permission = ['UPDATE_LOANAGENTOFFICER'];
export const addBlockAccount_permission = ['READ_SAVINGSACCOUNT', 'READ_BLOCKEDACCOUNT', 'CREATE_BLOCKEDACCOUNT'];
export const editBlockAccount_permission = ['READ_SAVINGSACCOUNT', 'READ_BLOCKEDACCOUNT', 'UPDATE_BLOCKEDACCOUNT'];
export const deleteBlockAccount_permission = ['DELETE_BLOCKEDACCOUNT'];

export const addOffices_permission = ['READ_CLIENT', 'READ_GLACCOUNT', 'READ_OFFICE', 'CREATE_OFFICE'];
export const editOffices_permission = ['READ_CLIENT', 'READ_GLACCOUNT', 'READ_OFFICE', 'UPDATE_OFFICE'];
export const editExternalService_permission = ['UPDATE_EXTERNALSERVICES'];
export const printCooperativeList_Permission = ['READ_Cooperative List', 'READ_Cooperative List By Group'];
export const transferLoanOverPaid_permission = ['READ_CLIENT', 'READ_ACCOUNTTRANSFER', 'CREATE_ACCOUNTTRANSFER'];
export const addLoanCharge_Permission = ['CREATE_LOANCHARGE'];
export const editLoanCharge_Permission = ['UPDATE_LOANCHARGE'];
export const deleteLoanCharge_Permission = ['DELETE_LOANCHARGE'];
export const waiveLoanCharge_Permission = ['WAIVE_LOANCHARGE'];
export const viewJournalEntriesDetail_Permission = ['READ_JOURNALENTRY'];
export const addPassbookType_permission = ['READ_TYPEPASSBOOK', 'CREATE_TYPEPASSBOOK'];
export const editPassbookType_permission = ['READ_TYPEPASSBOOK', 'UPDATE_TYPEPASSBOOK'];
export const deletePassbookType_permission = ['DELETE_TYPEPASSBOOK'];
export const resetPassbook_permission = ['READ_PRINTPASSBOOK', 'CREATE_PASSBOOKRESET', 'READ_SAVINGSACCOUNT', 'READ_TYPEPASSBOOK'];
export const printPassbookCover_permission = ['READ_SAVINGSACCOUNT', 'READ_TYPEPASSBOOK', 'READ_PRINTPASSBOOK', 'READ_PASSBOOKCOVER', 'CREATE_PASSBOOKCOVER', 'READ_Passbook Cover', 'CREATE_PASSBOOKCOVERPRINTED'];
export const printPassbook_permission = ['READ_TYPEPASSBOOK', 'READ_SAVINGSACCOUNT', 'READ_PRINTPASSBOOK', 'CREATE_PRINTPASSBOOK', 'READ_Savings Transactions Passbook'];
export const addReferralCode_Permission = ['READ_REFERRALCODE', 'READ_CHARGE', 'CREATE_REFERRALCODE'];
export const editReferralCode_Permission = ['READ_REFERRALCODE', 'READ_CHARGE', 'UPDATE_REFERRALCODE'];
export const deleteReferralCode_Permission = ['DELETE_REFERRALCODE'];

export const adddetailtransaksi_Permission = ['CREATE_CORETRANSACTIONCODEDETAIL'];
export const editdetailtransaksi_Permission = ['UPDATE_CORETRANSACTIONCODEDETAIL'];
export const deletedetailtransaksi_Permission = ['DELETE_CORETRANSACTIONCODEDETAIL'];
export const addtransaksioverdraft_Permission = ['CREATE_CORETRANSACTIONCODEOVERDRAFTDETAIL'];
export const edittransaksioverdraft_Permission = ['UPDATE_CORETRANSACTIONCODEOVERDRAFTDETAIL'];
export const deletetransaksioverdraft_Permission = ['DELETE_CORETRANSACTIONCODEOVERDRAFTDETAIL'];
export const addtransaksilimit_Permission = ['CREATE_CORETRANSACTIONCODELIMIT'];
export const edittransaksilimit_Permission = ['UPDATE_CORETRANSACTIONCODELIMIT'];
export const deletetransaksilimit_Permission = ['DELETE_CORETRANSACTIONCODELIMIT'];
export const addEmployeeInformation_Permission = ['READ_CLIENTJOBS', 'CREATE_CLIENTJOBS'];
export const editEmployeeInformation_Permission = ['READ_CLIENTJOBS', 'UPDATE_CLIENTJOBS'];
export const deleteEmployeeInformation_Permission = ['DELETE_CLIENTJOBS'];
export const addTransMode_Permission = ['READ_CORETRANSACTIONMODE', 'CREATE_CORETRANSACTIONMODE'];
export const editTransMode_Permission = ['READ_CORETRANSACTIONMODE', 'UPDATE_CORETRANSACTIONMODE'];
export const deleteTransMode_Permission = ['DELETE_CORETRANSACTIONMODE'];
export const addTransCode_Permission = ['READ_CORETRANSACTIONCODE', 'CREATE_CORETRANSACTIONCODE', 'READ_CHARGE'];
export const editTransCode_Permission = ['READ_CORETRANSACTIONCODE', 'UPDATE_CORETRANSACTIONCODE', 'READ_CHARGE', 'READ_CORETRANSACTIONMODE'];
export const deleteTransCode_Permission = ['DELETE_CORETRANSACTIONCODE'];
export const closeTermSavings_Permission = ['READ_FIXEDDEPOSITACCOUNT', 'READ_GLACCOUNT', 'CLOSE_FIXEDDEPOSITACCOUNT'];

export const dashboardTotalMemberCoopDetailByAgeApex_permission = ['READ_DASHBOARD_CLIENT_AGE'];
export const dashboardTotalMemberCoopDetailByGenderApex_permission = ['READ_DASHBOARD_CLIENT_GENDER'];
export const productTemplateAdd_Permission = ['CREATE_TEMPLATEPRODUCT','CREATE_TEMPLATE_PRODUCT_FIXED_DEPOSIT','CREATE_TEMPLATEPRODUCTPAYLATER','CREATE_TEMPLATEPRODUCTSAVING'];
export const productTemplateEdit_Permission = ['UPDATE_TEMPLATEPRODUCT','UPDATE_TEMPLATE_PRODUCT_FIXED_DEPOSIT','UPDATE_TEMPLATEPRODUCTPAYLATER','UPDATE_TEMPLATEPRODUCTSAVING'];
export const dashboardFinancingApex_permission = ['READ_DASHBOARD_LOAN_DISBURSE', 'READ_DASHBOARD_LOAN_MONTH'];

export const listApproverOffice_Permission = ['READ_OFFICEAPPROVAL', 'CREATE_OFFICEAPPROVAL', 'UPDATE_OFFICEAPPROVAL', 'DELETE_OFFICEAPPROVAL'];
export const detailMemberApproval_Permission = ['READ_CLIENTAPPROVAL', 'READ_REFERRALCODE'];
export const approveMemberApproval_Permission = ['APPROVE_CLIENTAPPROVAL'];
export const rejectMemberApproval_Permission = ['REJECT_CLIENTAPPROVAL'];

export const rejectedMember_permission = ['REJECT_CLIENT'];
export const withdrawSavings = ['READ_CORETRANSACTIONCODE', 'READ_CHARGE', 'CREATE_WITHDRAWALTRANSACTIONBACKOFFICE'];
export const updateSpecialRate_permission = ['READ_SAVINGSACCOUNT', 'UPDATE_SPECIALRATE'];
export const resetSpecialRate_permission = ['READ_SAVINGSACCOUNT', 'RESET_SPECIALRATE'];

export const detailUnitUser_permission = ['READ_UNITUSER', 'READ_USER'];
export const addUnitUser_permission = ['CREATE_UNITUSER', 'READ_USER'];
export const editUnitUser_permission = ['UPDATE_UNITUSER', 'READ_USER'];
export const deleteUnitUser_permission = ['DELETE_UNITUSER', 'READ_USER'];

export const addMember_UnitData = ['READ_CLIENT', 'ACTIVATE_CLIENT', 'CREATE_REGISTER_FROM_WEB', 'CREATE_UNITUSER', 'READ_USER'];
export const addSavingProduct = ['CREATE_SAVINGSPRODUCT', 'READ_SAVINGSPRODUCT', 'READ_TYPEPASSBOOK'];
export const editSavingProduct = ['UPDATE_SAVINGSPRODUCT', 'READ_SAVINGSPRODUCT', 'READ_TYPEPASSBOOK'];

export const modifyLoan_Permission = ['READ_LOAN', 'UPDATE_LOAN'];

export const addLoanPassBookType_Permission = ['CREATE_LOANPASSBOOKTYPE'];
export const editLoanPassBookType_Permission = ['READ_LOANPASSBOOKTYPE', 'UPDATE_LOANPASSBOOKTYPE'];
export const deleteLoanPassBookType_Permission = ['DELETE_LOANPASSBOOKTYPE'];

export const maintenanceSavingsAccount_Permission = ['READ_SAVINGSACCOUNT', 'UPDATE_SAVINGSACCOUNT'];

export const addEmploymentLevel_Permission = ['READ_EMPLOYMENTLEVEL', 'CREATE_EMPLOYMENTLEVEL'];
export const editEmploymentLevel_Permission = ['READ_EMPLOYMENTLEVEL', 'UPDATE_EMPLOYMENTLEVEL'];
export const deleteEmploymentLevel_Permission = ['DELETE_EMPLOYMENTLEVEL'];
export const lockInternalUser_Permission = ['CONFIRM_LOCK_INTERNAL_USER'];
export const lockExternalUser_Permission = ['CONFIRM_LOCK_EXTERNAL_USER'];

export const addTellerAccount_Permission = ['READ_TELLERLIMIT', 'CREATE_TELLERLIMIT'];
export const editTellerAccount_Permission = ['READ_TELLERLIMIT', 'UPDATE_TELLERLIMIT'];
export const deleteTellerAccount_Permission = ['DELETE_TELLERLIMIT'];
export const tellerStatusTellerAccount_Permission = ['READ_TELLERLIMIT', 'UPDATE_TELLERISOPENED'];
export const allocateCashTellerAccount_Permission = ['READ_TELLERLIMIT', 'READ_CURRENCY', 'READ_DENOMINATION', 'READ_FINANCIALACTIVITYACCOUNT', 'ALLOCATECASH_TELLERLIMIT'];
export const settleCashTellerAccount_Permission = ['READ_TELLERLIMIT', 'READ_CURRENCY', 'READ_DENOMINATION', 'READ_FINANCIALACTIVITYACCOUNT', 'SETTLECASH_TELLERLIMIT'];

export const addTellerLimit_Permission = ['READ_TRANSACTIONLIMIT', 'CREATE_TRANSACTIONLIMIT'];
export const editTellerLimit_Permission = ['READ_TRANSACTIONLIMIT', 'UPDATE_TRANSACTIONLIMIT'];
export const deleteTellerLimit_Permission = ['DELETE_TRANSACTIONLIMIT'];

export const addTellerDenom_Permission = ['READ_DENOMINATION', 'CREATE_DENOMINATION'];
export const editTellerDenom_Permission = ['READ_DENOMINATION', 'UPDATE_DENOMINATION'];
export const deleteTellerDenom_Permission = ['DELETE_DENOMINATION'];

export const detailTransactionApproval_permission = ['READ_AUDIT'];
export const approveRejectDelete_Permission = ['CREATE_WITHDRAWALTRANSACTION_CHECKER', 'CREATE_DEPOSITTRANSACTION_CHECKER', 'CREATE_OVERBOOKINGTRANSACTION_CHECKER', 'CREATE_TELLERGENERALTRANSACTION_CHECKER'];

export const addGLSummaryTabelaris_Permission = ['READ_GL_SUMMARY_PARAMETER', 'CREATE_GL_SUMMARY_PARAMETER'];
export const editGLSummaryTabelaris_Permission = ['READ_GL_SUMMARY_PARAMETER', 'UPDATE_GL_SUMMARY_PARAMETER'];
export const deleteGLSummaryTabelaris_Permission = ['DELETE_GL_SUMMARY_PARAMETER'];

export const printRePrintReceipt_Permission = ['CREATE_REPRINTPASSBOOK'];

export const addLoanProduct_Permission = ['READ_LOANPRODUCT', 'CREATE_LOANPRODUCT', 'READ_LOANPASSBOOKTYPE'];
export const editLoanProduct_Permission = ['READ_LOANPRODUCT', 'UPDATE_LOANPRODUCT', 'READ_LOANPASSBOOKTYPE'];

export const createDepositRecurringAccount_Permission = ['READ_RECURRINGDEPOSITACCOUNT', 'CREATE_RECURRINGDEPOSITACCOUNT'];
export const prematureCloseDepositRecurringAccount_Permission = ['PREMATURECLOSE_RECURRINGDEPOSITACCOUNT'];
export const closeDepositRecurringAccount_Permission = ['CLOSE_RECURRINGDEPOSITACCOUNT'];
export const depositRecurringAccount_SavingToRecurring_Permission = ['READ_CLIENT', 'READ_DOCUMENT', 'READ_CLIENTIMAGE', 'READ_SAVINGSACCOUNT', 'CREATE_SAVINGS_T0_RECURRING_BACK_OFFICE'];
export const depositRecurringAccount_SavingToRecurring_Teller_Permission = ['READ_CLIENT', 'READ_DOCUMENT', 'READ_CLIENTIMAGE', 'READ_SAVINGSACCOUNT', 'CREATE_SAVINGS_T0_RECURRING'];
export const inactivatedLoanCharges_Permission = ['INACTIVATE_LOANCHARGE'];
export const printBilyet_Permission = ['CREATE_PRINTBILYETNUMBER','READ_BILYETNUMBER','CREATE_BILYETNUMBER','READ_Bilyet Deposito'];
export const addMerchant_Permission = ['READ_MITRA_MERCHANT','CREATE_MITRA_MERCHANT'];
export const editMerchant_Permission = ['READ_MITRA_MERCHANT','UPDATE_MITRA_MERCHANT'];
export const approveMerchant_Permission = ['APPROVE_MITRA_MERCHANT'];
export const rejectMerchant_Permission = ['REJECT_MITRA_MERCHANT'];
export const reSubmitMerchant_Permission = ['READ_MITRA_MERCHANT_RECREATE','RECREATE_MITRA_MERCHANT'];
export const EditSavingsPlan_Permission = ['READ_RECURRINGDEPOSITACCOUNT','UPDATE_RECURRINGDEPOSITACCOUNT'];
export const TransferMember_Permission = ['READ_OFFICE','PROPOSETRANSFER_CLIENT'];
export const ApproveTransferMember_Permission = ['ACCEPTTRANSFER_CLIENT'];
export const RejectTransferMember_Permission = ['REJECTTRANSFER_CLIENT'];
export const EditTokenService_Permission = ['READ_TOKEN_SERVICE','CREATE_TOKEN_SERVICE'];
export const AddSavingCharge_Permission = ['READ_SAVINGSACCOUNTCHARGE','CREATE_SAVINGSACCOUNTCHARGE'];
export const EditSavingCharge_Permission = ['MAINTENANCE_SAVINGSCHARGE'];
export const addInterbranchSettlement_Permission = ['READ_INTERBRANCH_SETTLEMENT','CREATE_INTERBRANCH_SETTLEMENT'];
export const editInterbranchSettlement_Permission = ['READ_INTERBRANCH_SETTLEMENT','UPDATE_INTERBRANCH_SETTLEMENT'];
export const approveRejectInterbranchSettlement_Permission = ['ACTION_INTERBRANCH_SETTLEMENT'];
export const confirmedInterbranchSettlement_Permission = ['ACTION_INTERBRANCH_SETTLEMENT'];
export const printNotaPerpanjangan_Permission = ['READ_Deposit Renewal Notes'];
export const approveRejectSpecialToken_Permission = ['CONFIRM_TOKEN_SPECIAL'];
export const overbookingBackOffice_Permission = ['CREATE_PRINTPASSBOOK', 'READ_Savings Transactions Passbook', 'READ_Savings Transaction Overbooking Receipt', 'READ_SAVINGSACCOUNT', 'CREATE_OVERBOOKING_TRANSACTION_BACK_OFFICE', 'READ_DOCUMENT', 'READ_CHARGE', 'READ_CLIENTIMAGE'];
export const addMobileMenu_Permission = ['CREATE_MOBILE_MENU'];
export const editMobileMenu_Permission = ['READ_MOBILE_MENU','UPDATE_MOBILE_MENU'];
export const deleteMobileMenu_Permission = ['DELETE_MOBILE_MENU'];
export const detailMobileMenuGroup_Permission = ['READ_MOBILE_MENU_GROUP','READ_MOBILE_MENU_GROUP_DETAIL'];
export const addMobileMenuGroup_Permission = ['CREATE_MOBILE_MENU_GROUP'];
export const editMobileMenuGroup_Permission = ['UPDATE_MOBILE_MENU_GROUP','CREATE_MOBILE_MENU_GROUP'];
export const deleteMobileMenuGroup_Permission = ['DELETE_MOBILE_MENU_GROUP'];
export const addMobileMenuGroupDetail_Permission = ['CREATE_MOBILE_MENU_GROUP_DETAIL'];
export const editMobileMenuGroupDetail_Permission = ['UPDATE_MOBILE_MENU_GROUP_DETAIL'];
export const deleteMobileMenuGroupDetail_Permission = ['DELETE_MOBILE_MENU_GROUP_DETAIL'];
export const addMobileMenuMitra_Permission = ['CREATE_MOBILE_MENU_MITRA','READ_MOBILE_MENU_MITRA'];
export const editMobileMenuMitra_Permission = ['UPDATE_MOBILE_MENU_MITRA','READ_MOBILE_MENU_MITRA'];
export const deleteMobileMenuMitra_Permission = ['DELETE_MOBILE_MENU_MITRA'];
export const detailMobileMenuMitraTheme_Permission = ['READ_MOBILE_MENU_MITRA_THEME'];
export const addMobileMenuMitraTheme_Permission = ['CREATE_MOBILE_MENU_MITRA_THEME','READ_MOBILE_MENU_MITRA_THEME'];
export const editMobileMenuMitraTheme_Permission = ['UPDATE_MOBILE_MENU_MITRA_THEME','READ_MOBILE_MENU_MITRA_THEME'];
export const deleteMobileMenuMitraTheme_Permission = ['DELETE_MOBILE_MENU_MITRA_THEME'];
export const editMobileVersion_Permission = ['UPDATE_MOBILE_VERSION'];
export const addMobileCommerce_Permission = ['CREATE_COMMERCES'];
export const editMobileCommerce_Permission = ['READ_COMMERCES','UPDATE_COMMERCES'];
export const deleteMobileCommerce_Permission = ['DELETE_COMMERCES'];

export const addProductGroup_permission = ['CREATE_BANKPRODUCTGROUP'];
export const editProductGroup_permission = ['READ_BANKPRODUCTGROUP','UPDATE_BANKPRODUCTGROUP'];
export const deleteProductGroup_permission = ['DELETE_BANKPRODUCTGROUP'];
export const addDetailsProductGroup_permission = ['CREATE_BANKPRODUCTGROUPDETAIL'];
export const editDetailsProductGroup_permission = ['UPDATE_BANKPRODUCTGROUPDETAIL'];
export const deleteDetailsProductGroup_permission = ['DELETE_BANKPRODUCTGROUPDETAIL'];

export const addOrganisationPosition_permission = ['CREATE_ORGANISATIONPOSITION'];
export const editOrganisationPosition_permission = ['READ_ORGANISATIONPOSITION','UPDATE_ORGANISATIONPOSITION'];
export const deleteOrganisationPosition_permission = ['DELETE_ORGANISATIONPOSITION'];

export const addOrganisationStructure_permission = ['READ_ORGANISATIONSTRUCTURE','CREATE_ORGANISATIONSTRUCTURE'];
export const editOrganisationStructure_permission = ['READ_ORGANISATIONSTRUCTURE','UPDATE_ORGANISATIONSTRUCTURE'];
export const deleteOrganisationStructure_permission = ['DELETE_ORGANISATIONSTRUCTURE'];
export const printLoanApprovalAgreement_permission = ['READ_Loan Approval Agreement'];
export const AddLoanProvosioningParameter_Permission = ['VIEW_PROVISIONS','CREATE_PROVISIONCRITERIA'];
export const EditLoanProvosioningParameter_Permission = ['VIEW_PROVISIONS','UPDATE_PROVISIONCRITERIA'];
export const DeleteLoanProvosioningParameter_Permission = ['DELETE_PROVISIONCRITERIA'];
export const editApproverLoan_Permission = ['UPDATE_OFFICEAPPROVALLOAN'];
export const deleteApproverLoan_Permission = ['DELETE_OFFICEAPPROVALLOAN'];

export const addUserGroup_Permission = ['READ_USERGROUP','CREATE_USERGROUP'];
export const editUserGroup_Permission = ['READ_USERGROUP','UPDATE_USERGROUP'];
export const deleteUserGroup_Permission = ['DELETE_USERGROUP'];

export const editApproverMember_Permission = ['READ_OFFICEAPPROVAL','UPDATE_OFFICEAPPROVAL'];
export const deleteApproverMember_Permission = ['DELETE_OFFICEAPPROVAL'];

export const addMenuParameter_Permission = ['CREATE_PORTALMENU'];
export const editMenuParameter_Permission = ['UPDATE_PORTALMENU','READ_PORTALMENU'];
export const deleteMenuParameter_Permission = ['DELETE_PORTALMENU'];

export const detailMenuPermissionParameter_Permission = ['READ_PORTALMENU','READ_ROLEMENU'];
export const addMenuPermissionParameter_Permission = ['CREATE_ROLEMENU'];
export const editMenuPermissionParameter_Permission = ['UPDATE_ROLEMENU'];
export const deleteMenuPermissionParameter_Permission = ['DELETE_ROLEMENU'];

export const loan_Payment_Details_permission = ['READ_Loan Payment Detail'];
export const repayment_Schedule_permission = ['READ_Loan Repayment Schedule Report'];

//======================================= REPORT ==================================================
//Member
export const ReportMemberList = ['READ_CLIENT', 'READ_Client Listing - Person'];
export const ReportMemberestatement = ['READ_Client Statement'];
export const ReportMemberListingSimplePermission = ['READ_Client Listing Simple', 'READ_Client Listing Simple (CSV)'];
export const ReportEmployeeListingSimplePermission = ['READ_Employee Listing Simple', 'READ_Employee Listing Simple (CSV)'];

//Savings
export const ReportSavingsBalanceSummary = ['READ_CLIENT', 'READ_Client Savings Summary'];
export const ReportSavingList = ['READ_CLIENT', 'READ_Client Savings Account Summary'];
export const ReportAutoSweep = ['READ_Auto Sweep Report'];
export const ReportSavingAccountOpened_permission = ['READ_Savings Account Opened'];
export const ReportSavingAccountClosed_permission = ['READ_Savings Account Closed'];
export const ReportSavingTransaction_permission = ['READ_Savings Transactions (CSV)'];

//Term Savings
export const ReportTermSavingList = ['READ_CLIENT', 'READ_Fixed Deposit Opened'];
export const ReportTermSavingSummary = ['READ_CLIENT', 'READ_Client Fixed Deposit Summary'];
export const ReportMonthlyTermSavingMarginRanged_Permission = ['READ_Monthly Fixed Deposit Interest Report (Ranged)', 'READ_Monthly Fixed Deposit Interest Report (Ranged) (CSV)'];
export const ReportFixedDepositAccrueWillBePaid_Permission = ['READ_Fixed Deposit Accrue Will Be Paid', 'READ_Fixed Deposit Accrue Will Be Paid (CSV)'];
export const ReportTermSavingMarginDueReport_Permission = ['READ_Fixed Deposit Interest Due Report', 'READ_Fixed Deposit Interest Due Report (CSV)'];
export const ReportReportTermSavingWillBeMaturedReport_Permission = ['READ_Fixed Deposit Will Be Matured Report (CSV)'];
export const ReportFixedDepositClosed_Permission = ['READ_Fixed Deposit Closed', 'READ_Fixed Deposit Closed (CSV)'];
export const ReportTermSavingMaintenance_Permission = ['READ_Last Maintenance Fixed Deposit Report', 'READ_Last Maintenance Fixed Deposit Report (CSV)'];
export const ReportTotalTermSavingAccount_Permission = ['READ_Total Fixed Deposit Account'];

//Financing
export const ReportActiveFinancing = ['READ_CLIENT', 'READ_LOANPRODUCT', 'READ_STAFF', 'READ_Active Loans - Details(Pentaho)'];
export const ReportInstallmentPayment = ['READ_CLIENT', 'READ_LOANPRODUCT', 'READ_Report Loan Payment Simpool V3'];
export const ReportMonthlyLoanInstallmentList = ['READ_CLIENT', 'READ_Monthly Loan Installment List'];
export const ReportFinancingCollectibility = ['READ_CLIENT', 'READ_Financing Collecbility', 'READ_LOANPRODUCT'];
export const ReportFinancingProvosioning = ['READ_CLIENT', 'READ_Report Financing Provisioning', 'READ_LOANPRODUCT'];
export const ReportMonthlyMemberInstalmentList = ['READ_CLIENT', 'READ_Monthly Member Installment List'];
export const ReportFinancingPaidOffDetailss = ['READ_Loan Paid Off Details'];
export const ReportActiveFinancingByDisbursalPeriod_permission = ['READ_Active Loans by Disbursal Period', 'READ_Active Loans by Disbursal Period(Pentaho)'];
export const ReportRejectedFinancingRequest_permission = ['READ_Rejected Financing Request', 'READ_Rejected Financing Request (CSV)'];
export const ReportFinancingRepayment_permission = ['READ_Latest Financing Repayment Report', 'READ_Latest Financing Repayment Report (CSV)'];

//Accounting
export const ReportTrialBalance = ['READ_CLIENT', 'READ_Trial Balance'];
export const ReportIncomeStatement = ['READ_CLIENT', 'READ_Consolidated Income Statement', 'READ_Consolidated Income Statement (CSV)', 'READ_Income Statement', 'READ_Income Statement (CSV)'];
export const ReportBalanceSheet = ['READ_CLIENT', 'READ_Consolidated Balance Sheet', 'READ_Consolidated Balance Sheet (CSV)', 'READ_Balance Sheet', 'READ_Balance Sheet (CSV)'];
export const ReportGeneralLedger = ['READ_CLIENT', 'READ_GLACCOUNT', 'READ_General Ledger Report'];
export const ReportGLSummaryTabelaris = ['READ_Laporan Ringkasan General Ledger(Tabelaris) (CSV)'];
export const ReportTax_Permission = ['READ_Laporan Pemotongan Pajak','READ_Laporan Pemotongan Pajak (CSV)'];
export const ReportTaxSummary_Permission = ['READ_Laporan Pemotongan Pajak - Summary','READ_Laporan Pemotongan Pajak - Summary (CSV)'];
export const LaporanPermintaanPelimpahanAntarCabang_Permission = ['READ_Laporan Permintaan Pelimpahan Antar Cabang (Cabang Penerima)','READ_Laporan Permintaan Pelimpahan Antar Cabang (Cabang Penerima) (CSV)'];
export const LaporanPersetujuanPelimpahanAntarCabang_Permission = ['READ_Laporan Persetujuan Pelimpahan Antar Cabang (CSV)','READ_Laporan Persetujuan Pelimpahan Antar Cabang'];
export const LaporanPengirimanPelimpahanAntarCabang_Permission = ['READ_Laporan Pengiriman Pelimpahan Antar Cabang (Cabang Pengirim) (CSV)','READ_Laporan Pengiriman Pelimpahan Antar Cabang (Cabang Pengirim)'];
export const LaporanRekapPelimpahanAntarCabang_Permission = ['READ_Laporan Rekap Pelimpahan Antar Cabang (CSV)','READ_Laporan Rekap Pelimpahan Antar Cabang'];
export const ReportTaxMember_Permission = ['READ_Laporan Pemotongan Pajak Anggota','READ_Laporan Pemotongan Pajak Anggota (CSV)'];


//Paylater
export const ReportMemberPaylaterAccSummary = ['READ_CLIENT', 'READ_Client Paylater Account Summary'];
export const ReportMemberPaylaterSummary = ['READ_CLIENT', 'READ_Client Paylater Summary'];
export const ReportMemberPaylaterBalancePermission = ['READ_CLIENT', 'READ_Client Paylater Balance', 'READ_Client Paylater Balance (CSV)'];
export const ReportOpeningPaylaterPermission = ['READ_Paylater Opening Report', 'READ_Paylater Opening Report (CSV)'];
export const ReportPaylaterDisbursementSummaryReportPermission = ['READ_Paylater Disbursement Summary Report', 'READ_Paylater Disbursement Summary Report (CSV)'];
export const ReportPaylaterDisbursementPermission = ['READ_Paylater Disbursement Report', 'READ_Paylater Disbursement Report (CSV)'];

//Teller
export const ReportTellerCashAllocation = ['READ_Cash Allocation Report'];
export const ReportTellerCashSettlement = ['READ_Cash Settlement Report'];
export const ReportTellerDailyCash = ['READ_Daily Teller Cash Report'];


//Intermediary
export const ReportMemberIntermediaryAccSummary = ['READ_Client Intermediary Account Summary'];
export const ReportMemberIntermediarySummary = ['READ_Client Intermediary Summary'];

//Biller
export const ReportTransactionBillerByMonth = ['READ_Transaction Biller By Month', 'READ_Transaction Biller By Month Table'];
export const ReportTransactionBillerByYears = ['READ_Transaction Biller By Years'];
export const ReportTransactionBillerByCode = ['READ_Transaction Biller By TrxCode', 'READ_PRODUCT_GROUP', 'READ_MITRA', 'READ_Transaction Biller By TrxCode By Month Table'];
export const ReportTransactionKoperasiIn = ['READ_Transaction Antar Koperasi In', 'READ_Transaction Antar Koperasi In Table'];
export const ReportTransactionKoperasiOut = ['READ_Transaction Antar Koperasi Out', 'READ_Transaction Antar Koperasi Out Table'];
export const ReportTransactionCommerces_permission = ['READ_Transaction Commerces', 'READ_Transaction Commerces (CSV)'];
export const ReportTransactionKoperasi = ['READ_Transaction Antar Koperasi', 'READ_Transaction Antar Koperasi (CSV)'];
export const ReportRingkasanPengirimanPesan_permission = ['READ_Laporan Ringkasan Pengiriman Pesan', 'READ_Laporan Ringkasan Pengiriman Pesan(CSV)'];
export const ReportMemberTransactionActivity_permission = ['READ_Laporan Aktivitas Transaksi Anggota', 'READ_Laporan Aktivitas Transaksi Anggota(CSV)'];
export const ReportMemberTransactionActivityDetail_permission = ['READ_Laporan Aktivitas Transaksi Anggota - Rincian', 'READ_Laporan Aktivitas Transaksi Anggota - Rincian (CSV)'];

//DataImport
export const LoanRepaymentBulk_Permission = ['READ_FUND', 'READ_OFFICE', 'READ_CURRENCY', 'READ_PAYMENTTYPE', 'READ_CLIENT', 'REPAYMENT_LOAN'];
export const Update_EmployeeInformation_Permission = ['UPDATE_CLIENTJOBS_FOR_DMT', 'READ_OFFICE', 'READ_CLIENT'];
export const Update_PaylaterLimit_Permission = ['READ_PAYLATERACCOUNT', 'MAINTENANCE_SAVINGS'];
export const Update_Client_Permission = ['UPDATE_CLIENT_IMPORT'];

//Savings Plan / Recurring
export const ReportTabunganBerjangkaAktif_permission = ['READ_Laporan Tabungan Berjangka Aktif', 'READ_Laporan Tabungan Berjangka Aktif (CSV)'];
export const ReportTabunganBerjangkaJatuhTempo_permission = ['READ_Laporan Tabungan Berjangka Jatuh Tempo', 'READ_Laporan Tabungan Berjangka Jatuh Tempo (CSV)'];
export const ReportTabunganBerjangkaElapsed_permission = ['READ_Laporan Tabungan Berjangka Elapsed', 'READ_Laporan Tabungan Berjangka Elapsed (CSV)'];
export const ReportTabunganBerjangkaCairTutup_permission = ['READ_Laporan Tabungan Berjangka Cair Atau Tutup', 'READ_Laporan Tabungan Berjangka Cair Atau Tutup (CSV)'];
export const ReportDaftarTabunganBerjangka_permission = ['READ_Daftar Rekening Tabungan Berjangka', 'READ_Daftar Rekening Tabungan Berjangka (CSV)'];
export const ReportDaftarTabunganBerjangkaSetoranJatuhTempo_permission = ['READ_Laporan Setoran Tabungan Berjangka Jatuh Tempo', 'READ_Laporan Setoran Tabungan Berjangka Jatuh Tempo (CSV)'];

//======================================= MENU ==================================================
export const MenuDashboard = ['READ_ClientTrendByMonth', 'READ_AgeTrend', 'READ_ProviceTrend', 'READ_GenderTrend', 'READ_LoanTrendByMonth'];
export const MenuMember = ['READ_CLIENT'];
export const MenuSavings = ['READ_SAVINGSACCOUNT'];
export const MenuTermSavings = ['READ_SAVINGSACCOUNT'];
export const MenuFinancing = ['READ_LOAN'];
export const MenuMobileUser = ['READ_EXTERNALUSER'];
export const MenuFinancingApproval = ['READ_LOAN'];
export const MenuFinancingDisbursement = ['READ_LOAN'];
export const MenuToken = ['READ_TOKEN_SERVICE'];
export const MenuDepositBiller = ['READ_CORETRANSACTIONCODE', 'READ_SAVINGSACCOUNT', 'READ_CHARGE', 'CREATE_GENERALTRANSACTION'];
export const MenuSettlementVA = ['READ_CORETRANSACTIONCODE', 'READ_SAVINGSACCOUNT', 'READ_CHARGE', 'CREATE_GENERALTRANSACTION'];
export const MenuCOA = ['READ_GLACCOUNT'];
export const MenuFrequentPosting = ['READ_PAYMENTTYPE', 'READ_ACCOUNTINGRULE', 'READ_CURRENCY', 'READ_OFFICE', 'CREATE_JOURNALENTRY'];
export const MenuSettingFrequentPosting = ['READ_ACCOUNTINGRULE'];
export const MenuJournalEntries_Permission = ['READ_PAYMENTTYPE', 'READ_GLACCOUNT', 'READ_CURRENCY', 'READ_OFFICE', 'CREATE_JOURNALENTRY'];
export const MenuPaylater = ['READ_PAYLATERACCOUNT'];
export const MenuInternalUser = ['READ_USER'];
export const MenuAutoSweep = ['READ_AUTOSWEEP'];
export const MenuAutoSweepBulkException = ['READ_AUTOSWEEPBULKVALIDATION'];
export const MenuAutoSweepGroup = ['READ_AUTOSWEEPGROUP'];
export const MenuCharges = ['READ_CHARGE'];
export const MenuQrMerchant = ['READ_MITRA_MERCHANT'];
export const MenuCreateJournalRecurring_Permission = ['READ_GLRECURRING', 'READ_GLACCOUNT', 'READ_CURRENCY', 'READ_OFFICE', 'CREATE_GLRECURRING'];
export const MenuBlockAccount = ['READ_BLOCKEDACCOUNT'];
export const MenuPromotionManagement = ['READ_PROMOTION'];
export const MenuCreateDepositBiller = ['READ_DEPOSIT_BILLER'];
export const MenuCreateDepositBillerService = ['READ_DEPOSIT_BILLER_SERVICE'];
export const MenuIntermediary = ['READ_INTERMEDIARYACCOUNT'];
export const MenuAgentGroup = ['READ_AGENT_GROUP'];
export const MenuAgent = ['READ_AGENT'];
export const MenuFinancingCollectionSheet = ['READ_LOANAGENTOFFICER'];
export const MenuOffices = ['READ_OFFICE'];
export const MenuExternalService = ['READ_EXTERNALSERVICECONFIGURATION'];
export const MenuPassbookType = ['READ_TYPEPASSBOOK'];
export const MenuReferralCode = ['READ_REFERRALCODE'];
export const MenuEmployeeInformation = ['READ_CLIENTJOBS'];
export const MenuTransMode = ['READ_CORETRANSACTIONMODE'];
export const MenuProductTemplate = ['READ_TEMPLATEPRODUCT'];
export const MenuTransCode = ['READ_CORETRANSACTIONCODE', 'READ_CORETRANSACTIONCODELIMIT'];
export const MenuTransCodeControl = ['READ_GLACCOUNT', 'READ_CORETRANSACTIONCODE', 'READ_LOANPRODUCT', 'READ_CORETRANSACTIONCODE', 'UPDATE_CORETRANSACTIONCODEDETAIL', 'CREATE_CORETRANSACTIONCODEDETAIL'];
export const MenuMemberApproval = ['READ_CLIENTAPPROVAL'];
export const MenuUnitUser = ['READ_UNITUSER'];
export const MenuSavingsProduct = ['READ_SAVINGSPRODUCT'];
// export const MenuMobileUserNotLogin = ['READ_RESET_PASSWORD','CREATE_RESET_PASSWORD_EXTERNAL_USER'];
export const MenuMobileUserNotLogin = ['CREATE_RESET_PASSWORD_EXTERNAL_USER'];
export const MenuLoanPassbookType = ['READ_LOANPASSBOOKTYPE'];
export const MenuFinancePassbookCover = ['READ_LOANPASSBOOKTYPE', 'READ_ACCOUNTTRANSFER', 'READ_LOANPASSBOOKCOVER', 'CREATE_LOANPASSBOOKCOVER', 'CREATE_LOANPASSBOOKCOVERPRINTED', 'READ_Loan Passbook Cover'];
export const MenuFinanceResetPassbookCover = ['READ_LOANPASSBOOKTYPE', 'READ_ACCOUNTTRANSFER', 'READ_LOANPASSBOOKRESET', 'CREATE_LOANPASSBOOKRESET'];
export const MenuFinanceRePrintPassbookCover = ['READ_LOANPASSBOOKTYPE', 'READ_ACCOUNTTRANSFER', 'READ_REPRINTLOANPASSBOOK', 'CREATE_REPRINTLOANPASSBOOK', 'READ_Loan Transactions Passbook'];
export const MenuFinancePrintPassbookCover = ['READ_LOANPASSBOOKTYPE', 'READ_ACCOUNTTRANSFER', 'READ_PRINTLOANPASSBOOK', 'CREATE_PRINTLOANPASSBOOK', 'READ_Loan Transactions Passbook'];
export const MenuTellerCashDepositWithdrawal = ['CREATE_PRINTPASSBOOK', 'READ_Savings Transactions Passbook', 'READ_Savings Transaction Receipt', 'READ_SAVINGSACCOUNT', 'CREATE_DEPOSITTRANSACTION', 'READ_CHARGE'];
export const MenuTellerCashWithdrawal = ['CREATE_PRINTPASSBOOK', 'READ_Savings Transactions Passbook', 'READ_Savings Transaction Receipt', 'READ_SAVINGSACCOUNT', 'CREATE_WITHDRAWALTRANSACTION', 'READ_DOCUMENT', 'READ_CHARGE', 'READ_CLIENTIMAGE'];
export const MenuTellerCashGeneralTransaction = ['READ_Cash General Transaction Receipt', 'READ_CORETRANSACTIONCODE', 'CREATE_TELLERGENERALTRANSACTION'];
export const MenuEmploymentLevel = ['READ_EMPLOYMENTLEVEL'];
export const MenuTellerAccount = ['READ_CURRENCY', 'READ_TELLERLIMIT'];
export const MenuTellerLimit = ['READ_TRANSACTIONLIMIT'];
export const MenuTellerDenom = ['READ_DENOMINATION'];
export const MenuTellerOverbooking = ['CREATE_PRINTPASSBOOK', 'READ_Savings Transactions Passbook', 'READ_Savings Transaction Overbooking Receipt', 'READ_SAVINGSACCOUNT', 'CREATE_OVERBOOKINGTRANSACTION', 'READ_DOCUMENT', 'READ_CHARGE', 'READ_CLIENTIMAGE'];
export const MenuTellerApproval = ['READ_TELLERTRANSACTIONAPPROVALMENU'];
export const MenuGeneralTrxTeller = ['READ_SAVINGSACCOUNT', 'READ_DOCUMENT', 'READ_CLIENTIMAGE', 'READ_CHARGE', 'READ_General Transaction Receipt', 'CREATE_GENERAL_TRANSACTION_TELLER', 'READ_CORETRANSACTIONCODE'];
export const MenuAccountingGeneralTrx = ['READ_SAVINGSACCOUNT', 'READ_DOCUMENT', 'READ_CLIENTIMAGE', 'READ_CHARGE', 'READ_General Transaction Receipt', 'CREATE_GENERALTRANSACTION'];
export const MenuGLSummaryTabelLaris = ['READ_GL_SUMMARY_PARAMETER'];
export const MenuRePrintReceiptSavings = ['READ_PRINTPASSBOOK'];
export const MenuLoanSubmission = ['READ_LOAN'];
export const MenuPeriodicReport = ['READ_REPORT_JOB'];
export const MenuLoanProduct = ['READ_LOANPRODUCT'];
export const MenuSavingsPlan = ['READ_RECURRINGDEPOSITACCOUNT'];
export const MenuSpesialToken = ['READ_TOKEN_SPECIAL'];
export const MenuLoanProvosioningParameter = ['VIEW_PROVISIONS'];
export const MenuTransferMemberApproval = ['READ_TRANSFERCLIENT'];
export const MenuInterBranchSettlement = ['READ_INTERBRANCH_SETTLEMENT'];
export const MenuInterBranchSettlementApprove = ['READ_INTERBRANCH_SETTLEMENT'];
export const MenuInterBranchSettlementConfirm = ['READ_INTERBRANCH_SETTLEMENT'];
export const MenuMerchantList = ['READ_MITRA_MERCHANT'];
export const MenuMerchantApprove = ['READ_MITRA_MERCHANT'];
export const MenuReSubmitMerchant = ['READ_MITRA_MERCHANT_RECREATE'];
export const MenuMobileMenu = ['READ_MOBILE_MENU'];
export const MenuMobileMenuGroup = ['READ_MOBILE_MENU_GROUP'];
export const MenuMobileMenuMitra = ['READ_MOBILE_MENU_MITRA'];
export const MenuMobileVersion = ['READ_MOBILE_VERSION'];
export const MenuMobileCommerces = ['READ_COMMERCES'];
export const MenuSchedulerJobs = ['READ_SCHEDULER'];
export const MenuApproverLoan = ['READ_OFFICEAPPROVALLOAN','CREATE_OFFICEAPPROVALLOAN'];
export const MenuApproverUserGroup = ['READ_USERGROUP'];
export const MenuApproverMember = ['READ_OFFICEAPPROVAL','CREATE_OFFICEAPPROVAL'];
export const MenuProductGroups = ['READ_BANKPRODUCTGROUP'];
export const MenuOrganisationPosition = ['READ_ORGANISATIONPOSITION'];
export const MenuOrganisationStructure = ['READ_ORGANISATIONSTRUCTURE'];
export const MenuMenuParameter = ['READ_PORTALMENU'];
export const MenuPermissionParameter = ['READ_ROLE'];
export const MenuSavingsToLoan = ['CREATE_SAVINGSTOLOAN','READ_Loan Repayment Receipt','CREATE_PRINTPASSBOOK','READ_Loan Transactions Passbook','READ_DOCUMENT','READ_CLIENTIMAGE','READ_SAVINGSACCOUNT','READ_LOAN'];

//======================================= PORTAL MENU PARAMETER ==================================================
//ISHEADER = FLAG MENU HEADER DAN SUBMENU ADA PARAMETER NYA JUGA

//Profile, Change Password
export const Portal_Header = ['HEADER'];

//Dashboard and Chart
export const Portal_Dashboard = ['DASHBOARD'];

//Member information & Accounts

export const Portal_Member = ['MEMBER'];
export const Portal_MemberData = ['MMBRD'];
export const Portal_Member_Savings = ['MMSAV'];
export const Portal_Member_AutoSweep = ['MAUSW'];
export const Portal_Member_Paylater = ['MPYLTR'];
export const Portal_Member_TermSaving = ['MMTSV'];
export const Portal_Member_SavingPlan = ['MMSVP'];
export const Portal_Member_Financing = ['MMFIN'];
export const Portal_Member_MobileUser = ['MMBLU'];
export const Portal_Member_BlockingAccount = ['MMBLA'];
export const Portal_Member_MultiDeposit = ['MMMLD'];
export const Portal_Member_Agent = ['MAGEN'];
export const Portal_Member_Merchant = ['MMRCH'];
export const Portal_Member_CollectionAgent = ['MMCOL'];

//Coop Unit
export const Portal_CoopUnit = ['COOPUNIT'];
export const Portal_CoopUnit_UnitData = ['CUDTA'];
export const Portal_CoopUnit_UnitUser = ['CUUSR'];
export const Portal_CoopUnit_Intermediary = ['CINTR'];
//


//Cash Transaction Related
export const Portal_Cashier = ['CASHIER'];

//Loan Approval & Disbursement
export const Portal_Approval = ['APPROVAL'];
export const Portal_Approval_FinancingApproval = ['AFINA'];
export const Portal_Approval_FinancingDisbursement = ['AFIND'];
export const Portal_Approval_FinancingSubmission = ['AFINS'];
export const Portal_Approval_MemberApproval = ['AMEMB'];
export const Portal_Approval_MemberActivation = ['AMACT'];
export const Portal_Approval_MerchantApproval = ['AMERC'];
export const Portal_Approval_MemberTransferApproval = ['ATRMB'];

//Accounting, Entries & Transactions
export const Portal_Accounting = ['ACCOUNTING'];
export const Portal_Accounting_FreqPosting = ['ACFRP'];
export const Portal_Accounting_GeneralTrx = ['ACGTR'];
export const Portal_Accounting_AccJournal = ['ACJOU'];
export const Portal_Accounting_InterbranchSettlement = ['ACIBS'];


//Token Information & Transactions
export const Portal_Token = ['TOKEN'];

//Biller; PPOB & Disbursement Deposit 
export const Portal_Biller = ['BILLER'];

//All Report
export const Portal_Report = ['REPORT'];
export const Portal_Report_Member = ['RMEMB'];
export const Portal_Report_Savings = ['RSAVS'];
export const Portal_Report_SavingPlan = ['RSVPL'];
export const Portal_Report_Paylater = ['RPYLT'];
export const Portal_Report_Teller = ['RTELR'];
export const Portal_Report_Intermediary = ['RINTR'];
export const Portal_Report_TermSaving = ['RTRSV'];
export const Portal_Report_Financing = ['RFINA'];
export const Portal_Report_Accounting = ['RACCT'];
export const Portal_Report_Periodic = ['RPRRP'];


//All Setting
export const Portal_Setting = ['SETTING'];
export const Portal_Setting_organisation = ['SORGS'];
export const Portal_Setting_product = ['SPROD'];
export const Portal_Setting_accounting = ['SACCT'];
export const Portal_Setting_mobile = ['SMOBM'];
export const Portal_Setting_message = ['SMSSG'];
export const Portal_Setting_token = ['STOKN'];
export const Portal_Setting_user = ['SUSER'];
export const Portal_Setting_security = ['SSECR'];
export const Portal_Setting_externalservice = ['SEXTR'];
export const Portal_Setting_system = ['SSYST'];
export const Portal_Grup_Setting = ['SORGS', 'SPROD', 'SACCT', 'SMOBM', 'SMSSG', 'STOKN', 'SUSER', 'SSECR', 'SEXTR', 'SSYST'];

export const Portal_Data_Import = ['DATAIMPORT'];

//Teller
export const Portal_Teller = ['TELLER'];
export const Portal_HeadTeller = ['HEADTELLER'];


// ****** Menu UI Dashboard Cashier ******
export const changeStatusPermission = ['UPDATE_STATUS_TELLER_DASHBOARD'];

export const addTabOperation = ['CREATE_ACCOUNT_TELLER_DASHBOARD', 'READ_TELLERLIMIT'];
export const editTabOperation = ['UPDATE_ACCOUNT_TELLER_DASHBOARD', 'READ_TELLERLIMIT', 'READ_ACCOUNT_TELLER_DASHBOARD'];
export const deleteTabOperation = ['DELETE_ACCOUNT_TELLER_DASHBOARD'];
export const allocateTabOperation = ['READ_CURRENCY', 'READ_TELLERLIMIT', 'READ_DENOMINATION', 'READ_FINANCIALACTIVITYACCOUNT', 'READ_ACCOUNT_TELLER_DASHBOARD', 'CREATE_ALLOCATE_TELLER_DASHBOARD'];

export const settleCashCashierDashboard = ['READ_TELLERLIMIT', 'READ_CURRENCY', 'READ_DENOMINATION', 'READ_FINANCIALACTIVITYACCOUNT', 'CREATE_SETTLE_TELLER_DASHBOARD'];
export const menuTabOperation = ['READ_CURRENCY', 'READ_ACCOUNT_TELLER_DASHBOARD'];
export const menuDashboardCashier = ['READ_TELLER_DASHBOARD'];
export * from './settings.actions.js';
export * from './themes.actions.js';

export const AUTH_USER = 'AUTH_USER';
export const AUTH_USEROTP = 'AUTH_USEROTP';
export const AUTH_START = 'AUTH_START';
export const AUTH_SUCCESS = 'AUTH_SUCCESS';
export const AUTH_FAIL = 'AUTH_FAIL';
export const AUTH_OTP = 'AUTH_OTP';
export const AUTH_OTP_START = 'AUTH_OTP_START';
export const AUTH_OTP_SUCCESS = 'AUTH_OTP_SUCCESS';
export const AUTH_OTP_FAIL = 'AUTH_OTP_FAIL';
export const AUTH_INITIATE_LOGOUT = 'AUTH_INITIATE_LOGOUT';
export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_CHECK_STATE = 'AUTH_CHECK_STATE';
export const AUTH_LOGOUT_WITHERROR = 'AUTH_LOGOUT_WITHERROR';
export const AUTH_LOGOUT_WITHERROR_SUCCESS = 'AUTH_LOGOUT_WITHERROR_SUCCESS';
export const AUTH_USER_RETRIEVE = 'AUTH_USER_RETRIEVE';

export const INIT_MEMBER_LIST = 'INIT_MEMBER_LIST';
export const FETCH_MEMBER_LIST = 'FETCH_MEMBER_LIST';
export const FETCH_MEMBER_FAILED = 'FETCH_MEMBER_FAILED';
export const CHECK_DUPLICATE = 'CHECK_DUPLICATE';
export const INIT_MEMBER_TEMPLATE = 'INIT_MEMBER_TEMPLATE';
export const INIT_MEMBER_TEMPLATE_2 = 'INIT_MEMBER_TEMPLATE_2';
export const FETCH_MEMBER_TEMPLATE = 'FETCH_MEMBER_TEMPLATE';
export const FETCH_MEMBER_TEMPLATE_FAILED = 'FETCH_MEMBER_TEMPLATE_FAILED';
export const INIT_MEMBER_ADD = 'INIT_MEMBER_ADD';
export const MEMBER_ADD_FAILED = 'MEMBER_ADD_FAILED';
export const MEMBER_ADD_START = 'MEMBER_ADD_START';
export const MEMBER_ADD_SUCCESS = 'MEMBER_ADD_SUCCESS';
export const MEMBER_ADD_IMAGE = 'MEMBER_ADD_IMAGE';
export const MEMBER_ADD_IMAGE_START = 'MEMBER_ADD_IMAGE_START';
export const MEMBER_ADD_IMAGE_FAILED = 'MEMBER_ADD_IMAGE_FAILED';
export const MEMBER_ADD_IMAGE_SUCCESS = 'MEMBER_ADD_IMAGE_SUCCESS';
export const MEMBER_ADD_DOCUMENT = 'MEMBER_ADD_DOCUMENT';
export const MEMBER_ADD_DOCUMENT_START = 'MEMBER_ADD_DOCUMENT_START';
export const MEMBER_ADD_DOCUMENT_FAILED = 'MEMBER_ADD_DOCUMENT_FAILED';
export const MEMBER_ADD_DOCUMENT_SUCCESS = 'MEMBER_ADD_DOCUMENT_SUCCESS';

export const INIT_GET_MEMBER_ID = 'INIT_GET_MEMBER_ID';
export const GET_MEMBER_ID_START = 'GET_MEMBER_ID_START';
export const GET_MEMBER_ID_FAILED = 'GET_MEMBER_ID_FAILED';
export const GET_MEMBER_ID_SUCCESS = 'GET_MEMBER_ID_SUCCESS';
export const INIT_GET_MEMBER_ACCOUNT = 'INIT_GET_MEMBER_ACCOUNT';
export const GET_MEMBER_ACCOUNT_START = 'GET_MEMBER_ACCOUNT_START';
export const GET_MEMBER_ACCOUNT_FAILED = 'GET_MEMBER_ACCOUNT_FAILED';
export const GET_MEMBER_ACCOUNT_SUCCESS = 'GET_MEMBER_ACCOUNT_SUCCESS';
export const INIT_GET_MEMBER_SUMMARY = 'INIT_GET_MEMBER_SUMMARY';
export const GET_MEMBER_SUMMARY_START = 'GET_MEMBER_SUMMARY_START';
export const GET_MEMBER_SUMMARY_FAILED = 'GET_MEMBER_SUMMARY_FAILED';
export const GET_MEMBER_SUMMARY_SUCCESS = 'GET_MEMBER_SUMMARY_SUCCESS';
export const INIT_GET_MEMBER_IMAGE = 'INIT_GET_MEMBER_IMAGE';
export const GET_MEMBER_IMAGE_START = 'GET_MEMBER_IMAGE_START';
export const GET_MEMBER_IMAGE_FAILED = 'GET_MEMBER_IMAGE_FAILED';
export const GET_MEMBER_IMAGE_SUCCESS = 'GET_MEMBER_IMAGE_SUCCESS';
export const INIT_GET_MEMBER_DOC = 'INIT_GET_MEMBER_DOC';
export const GET_MEMBER_DOC_START = 'GET_MEMBER_DOC_START';
export const GET_MEMBER_DOC_FAILED = 'GET_MEMBER_DOC_FAILED';
export const GET_MEMBER_DOC_SUCCESS = 'GET_MEMBER_DOC_SUCCESS';

export const INIT_DELETE_MEMBER_IMAGE = 'INIT_DELETE_MEMBER_IMAGE';
export const DELETE_MEMBER_IMAGE_START = 'DELETE_MEMBER_IMAGE_START';
export const DELETE_MEMBER_IMAGE_SUCCESS = 'DELETE_MEMBER_IMAGE_SUCCESS';
export const DELETE_MEMBER_IMAGE_FAILED = 'DELETE_MEMBER_IMAGE_FAILED';
export const INIT_DELETE_MEMBER_DOC = 'INIT_DELETE_MEMBER_DOC';
export const DELETE_MEMBER_DOC_START = 'DELETE_MEMBER_DOC_START';
export const DELETE_MEMBER_DOC_SUCCESS = 'DELETE_MEMBER_DOC_SUCCESS';
export const DELETE_MEMBER_DOC_FAILED = 'DELETE_MEMBER_DOC_FAILED';
export const INIT_GET_DOCUMENT = 'INIT_GET_DOCUMENT';
export const GET_DOCUMENT_START = 'GET_DOCUMENT_START';
export const GET_DOCUMENT_SUCCESS = 'GET_DOCUMENT_SUCCESS';
export const GET_DOCUMENT_FAILED = 'GET_DOCUMENT_FAILED';


export const INIT_GET_MEMBER_EDIT = 'INIT_GET_MEMBER_EDIT';
export const GET_MEMBER_EDIT_START = 'GET_MEMBER_EDIT_START';
export const GET_MEMBER_EDIT_SUCCESS = 'GET_MEMBER_EDIT_SUCCESS';
export const GET_MEMBER_EDIT_FAILED = 'GET_MEMBER_EDIT_FAILED';

export const INIT_MEMBER_EDIT = 'INIT_MEMBER_EDIT';
export const MEMBER_EDIT_START = 'MEMBER_EDIT_START';
export const MEMBER_EDIT_SUCCESS = 'MEMBER_EDIT_SUCCESS';
export const MEMBER_EDIT_FAILED = 'MEMBER_EDIT_FAILED';

export const INIT_GET_MEMBER_SAVING_DETAIL = 'INIT_GET_MEMBER_SAVING_DETAIL';
export const GET_MEMBER_SAVING_DETAIL_START = 'GET_MEMBER_SAVING_DETAIL_START';
export const GET_MEMBER_SAVING_DETAIL_FAILED = 'GET_MEMBER_SAVING_DETAIL_FAILED';
export const GET_MEMBER_SAVING_DETAIL_SUCCESS = 'GET_MEMBER_SAVING_DETAIL_SUCCESS';

export const INIT_GET_MEMBER_DEPOSIT_DETAIL = 'INIT_GET_MEMBER_DEPOSIT_DETAIL';
export const GET_MEMBER_DEPOSIT_DETAIL_START = 'GET_MEMBER_DEPOSIT_DETAIL_START';
export const GET_MEMBER_DEPOSIT_DETAIL_FAILED = 'GET_MEMBER_DEPOSIT_DETAIL_FAILED';
export const GET_MEMBER_DEPOSIT_DETAIL_SUCCESS = 'GET_MEMBER_DEPOSIT_DETAIL_SUCCESS';

export const INIT_GET_MEMBER_SAVING_QR = 'INIT_GET_MEMBER_SAVING_QR';
export const GET_MEMBER_SAVING_QR_START = 'GET_MEMBER_SAVING_QR_START';
export const GET_MEMBER_SAVING_QR_FAILED = 'GET_MEMBER_SAVING_QR_FAILED';
export const GET_MEMBER_SAVING_QR_SUCCESS = 'GET_MEMBER_SAVING_QR_SUCCESS';

export const INIT_GET_SAVING_ACC_TX = 'INIT_GET_SAVING_ACC_TX';
export const GET_SAVING_ACC_TX_START = 'GET_SAVING_ACC_TX_START';
export const GET_SAVING_ACC_TX_FAILED = 'GET_SAVING_ACC_TX_FAILED';
export const GET_SAVING_ACC_TX_SUCCESS = 'GET_SAVING_ACC_TX_SUCCESS';

export const INIT_GET_SAVING_ACC_TRANSFER = 'INIT_GET_SAVING_ACC_TRANSFER';
export const GET_SAVING_ACC_TRANSFER_START = 'GET_SAVING_ACC_TRANSFER_START';
export const GET_SAVING_ACC_TRANSFER_FAILED = 'GET_SAVING_ACC_TRANSFER_FAILED';
export const GET_SAVING_ACC_TRANSFER_SUCCESS = 'GET_SAVING_ACC_TRANSFER_SUCCESS';

export const INIT_GET_DEPOSIT_ACC_TX = 'INIT_GET_DEPOSIT_ACC_TX';
export const GET_DEPOSIT_ACC_TX_START = 'GET_DEPOSIT_ACC_TX_START';
export const GET_DEPOSIT_ACC_TX_FAILED = 'GET_DEPOSIT_ACC_TX_FAILED';
export const GET_DEPOSIT_ACC_TX_SUCCESS = 'GET_DEPOSIT_ACC_TX_SUCCESS';

export const INIT_GET_DEPOSIT_ACC_TRANSFER = 'INIT_GET_DEPOSIT_ACC_TRANSFER';
export const GET_DEPOSIT_ACC_TRANSFER_START = 'GET_DEPOSIT_ACC_TRANSFER_START';
export const GET_DEPOSIT_ACC_TRANSFER_FAILED = 'GET_DEPOSIT_ACC_TRANSFER_FAILED';
export const GET_DEPOSIT_ACC_TRANSFER_SUCCESS = 'GET_DEPOSIT_ACC_TRANSFER_SUCCESS';

export const INIT_POST_SAVING_ACC = 'INIT_POST_SAVING_ACC';
export const POST_SAVING_ACC_START = 'POST_SAVING_ACC_START';
export const POST_SAVING_ACC_FAILED = 'POST_SAVING_ACC_FAILED';
export const POST_SAVING_ACC_SUCCESS = 'POST_SAVING_ACC_SUCCESS';

export const INIT_GET_PRODUCT_SAVING = 'INIT_GET_PRODUCT_SAVING';
export const GET_PRODUCT_SAVING_START = 'GET_PRODUCT_SAVING_START';
export const GET_PRODUCT_SAVING_FAILED = 'GET_PRODUCT_SAVING_FAILED';
export const GET_PRODUCT_SAVING_SUCCESS = 'GET_PRODUCT_SAVING_SUCCESS';

export const FETCH_SAVING_LIST = 'FETCH_SAVING_LIST';
export const INIT_GET_LIST_SAVING = 'INIT_GET_LIST_SAVING';
export const GET_LIST_SAVING_START = 'GET_LIST_SAVING_START';
export const GET_LIST_SAVING_FAILED = 'GET_LIST_SAVING_FAILED';
export const GET_LIST_SAVING_SUCCESS = 'GET_LIST_SAVING_SUCCESS';

export const INIT_FETCH_DASHBOARD_GENDER = 'INIT_FETCH_DASHBOARD_GENDER';
export const FETCH_DASHBOARD_GENDER_START = 'FETCH_DASHBOARD_GENDER_START';
export const FETCH_DASHBOARD_GENDER_FAILED = 'FETCH_DASHBOARD_GENDER_FAILED';
export const FETCH_DASHBOARD_GENDER_SUCCESS = 'FETCH_DASHBOARD_GENDER_SUCCESS';

export const INIT_FETCH_DASHBOARD_AGE = 'INIT_FETCH_DASHBOARD_AGE';
export const FETCH_DASHBOARD_AGE_START = 'FETCH_DASHBOARD_AGE_START';
export const FETCH_DASHBOARD_AGE_FAILED = 'FETCH_DASHBOARD_AGE_FAILED';
export const FETCH_DASHBOARD_AGE_SUCCESS = 'FETCH_DASHBOARD_AGE_SUCCESS';

export const INIT_FETCH_DASHBOARD_PROVINCE = 'INIT_FETCH_DASHBOARD_PROVINCE';
export const FETCH_DASHBOARD_PROVINCE_START = 'FETCH_DASHBOARD_PROVINCE_START';
export const FETCH_DASHBOARD_PROVINCE_FAILED = 'FETCH_DASHBOARD_PROVINCE_FAILED';
export const FETCH_DASHBOARD_PROVINCE_SUCCESS = 'FETCH_DASHBOARD_PROVINCE_SUCCESS';

export const INIT_FETCH_DASHBOARD_CLIENTMONTH = 'INIT_FETCH_DASHBOARD_CLIENTMONTH';
export const FETCH_DASHBOARD_CLIENTMONTH_START = 'FETCH_DASHBOARD_CLIENTMONTH_START';
export const FETCH_DASHBOARD_CLIENTMONTH_FAILED = 'FETCH_DASHBOARD_CLIENTMONTH_FAILED';
export const FETCH_DASHBOARD_CLIENTMONTH_SUCCESS = 'FETCH_DASHBOARD_CLIENTMONTH_SUCCESS';

export const REPORTS_MEMBER = 'REPORTS_MEMBER';

export const GET_STAFF_LOAN_OFFICER = 'GET_STAFF_LOAN_OFFICER';
export const GET_LOAN_PRODUCT = 'GET_LOAN_PRODUCT';
export const GET_LOAN_DETAIL = 'GET_LOAN_DETAIL';

export const GET_LIST_GL_ACCOUNT = 'GET_LIST_GL_ACCOUNT';

export const FETCH_MOBILEUSER_LIST = 'FETCH_MOBILEUSER_LIST';
export const INIT_GET_LIST_MOBILEUSER = 'INIT_GET_LIST_MOBILEUSER';
export const GET_LIST_MOBILEUSER_START = 'GET_LIST_MOBILEUSER_START';
export const GET_LIST_MOBILEUSER_FAILED = 'GET_LIST_MOBILEUSER_FAILED';
export const GET_LIST_MOBILEUSER_SUCCESS = 'GET_LIST_MOBILEUSER_SUCCESS';
export const GET_MOBILEUSER_BY_ID = 'GET_MOBILEUSER_BY_ID';
export const DELETE_MOBILEUSER_BY_ID = 'DELETE_MOBILEUSER_BY_ID';
export const UNLOCK_MOBILEUSER_BY_ID = 'UNLOCK_MOBILEUSER_BY_ID';
export const RESEND_PASSWORD_MOBILEUSER = 'RESEND_PASSWORD_MOBILEUSER';
export const CREATE_CHANGE_PASSWORD_MOBILEUSER = 'CREATE_CHANGE_PASSWORD_MOBILEUSER';
export const CONFIRM_CHANGE_PASSWORD_MOBILEUSER = 'CONFIRM_CHANGE_PASSWORD_MOBILEUSER';
export const UPDATE_MOBILE_USER = 'UPDATE_MOBILE_USER';
export const RESET_PIN_MOBILE_USER = 'RESET_PIN_MOBILE_USER';
export const FETCH_LOAN_LIST = 'FETCH_LOAN_LIST';
export const INIT_GET_LIST_LOAN = 'INIT_GET_LIST_LOAN';

export const FETCH_LOAN_LIST_NEED_APPROVE = 'FETCH_LOAN_LIST_NEED_APPROVE';
export const GET_LIST_LOAN_SEARCH_QUERY = 'GET_LIST_LOAN_SEARCH_QUERY';
export const SUBMIT_LOAN_APPROVE_OR_REJECT = 'SUBMIT_LOAN_APPROVE_OR_REJECT';
export const FETCH_LOAN_LIST_NEED_DISBURSMENT = 'FETCH_LOAN_LIST_NEED_DISBURSMENT';


export const FETCH_MERCHANT_LIST = 'FETCH_MERCHANT_LIST';
export const INIT_GET_LIST_MERCHANT = 'INIT_GET_LIST_MERCHANT';


export const GET_FIND_ACCOUNT_AND_GL = 'GET_FIND_ACCOUNT_AND_GL';
export const GET_ALL_CODE_TRX = 'GET_ALL_CODE_TRX';
export const SAVINGS_GENERAL_TRANSACTION = 'SAVINGS_GENERAL_TRANSACTION';
export const GET_FEE_BY_TRX_MODE = 'GET_FEE_BY_TRX_MODE';

export const INIT_GET_LIST_SAVINGS_BY_ACCOUNT_NUMBER = 'INIT_GET_LIST_SAVINGS_BY_ACCOUNT_NUMBER';
export const INIT_GET_LIST_PROVISIONINGCATEGORY = 'INIT_GET_LIST_PROVISIONINGCATEGORY';
export const INIT_GET_LIST_SAVINGS_INTERMEDIATE = 'INIT_GET_LIST_SAVINGS_INTERMEDIATE';
export const INIT_GET_DETAIL_CORE_TRANS_DETAIL = 'INIT_GET_DETAIL_CORE_TRANS_DETAIL';

export const GET_TOKEN_SERVIE = 'GET_TOKEN_SERVICE';
export const GET_LIST_TRANSACTION_HISTORY = 'GET_LIST_TRANSACTION_HISTORY';
export const GET_LIST_TRANSACTION_HISTORY_TOP_UP = 'GET_LIST_TRANSACTION_HISTORY_TOP_UP';
export const GET_TEMPLATE_TOKEN = 'GET_TEMPLATE_TOKEN';
export const CREATE_TOKEN = 'CREATE_TOKEN';
export const GET_TEMPLATE_SERVICE_TOKEN = 'GET_TEMPLATE_SERVICE_TOKEN';
export const INSERT_SERVICE_TOKEN = 'INSERT_SERVICE_TOKEN';
export const SAVINGS_ACTION_BY_COMMAND = 'SAVINGS_ACTION_BY_COMMAND';
export const FETCH_LIST_COA = 'FETCH_LIST_COA';
export const GET_COA_BY_ID = 'GET_COA_BY_ID';
export const GET_GL_TEMPLATE = 'GET_GL_TEMPLATE';
export const GET_LOANS_TEMPLATE = 'GET_LOANS_TEMPLATE';
export const CREATE_LOANS = 'CREATE_LOANS';
export const SIMULATION_LOANS_SCHEDULE = 'SIMULATION_LOANS_SCHEDULE';
export const GET_DEPOSIT_TEMPLATE = 'GET_DEPOSIT_TEMPLATE';
export const GET_SAVING_PRODUCT_BY_ID = 'GET_SAVING_PRODUCT_BY_ID';
export const GET_DEPOSIT_CHART = 'GET_DEPOSIT_CHART';
export const DEPOSIT_CREATE = 'DEPOSIT_CREATE';
export const GET_SAVING_DETAIL_ASSOCIATIONS_EMPTY = 'GET_SAVING_DETAIL_ASSOCIATIONS_EMPTY';
export const PAYMENT_TO_LOAN = 'PAYMENT_TO_LOAN';
export const GET_SAVINGS_BY_CLIENTID = 'GET_SAVINGS_BY_CLIENTID';
export const INIT_GET_DETAIL_CORE_TRANS_DETAIL_PARAM = 'INIT_GET_DETAIL_CORE_TRANS_DETAIL_PARAM';
export const INIT_GET_LIST_SAVINGS_INTERMEDIATE_PARAM = 'INIT_GET_LIST_SAVINGS_INTERMEDIATE_PARAM';

export const CREATE_CHANGE_PASSWORD_USER_WEB_OTP = 'CREATE_CHANGE_PASSWORD_USER_WEB_OTP';
export const CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP = 'CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP';
export const GET_PASSWORD_VALIDATION = 'GET_PASSWORD_VALIDATION';
export const GET_USER_PROFILE = 'GET_USER_PROFILE';
export const GET_GL_TEMPLATE_WITH_TYPE = 'GET_GL_TEMPLATE_WITH_TYPE';
export const SUBMIT_GL_ACCOUNT = 'SUBMIT_GL_ACCOUNT';
export const GET_GL_ACCOUNT_BY_ID = 'GET_GL_ACCOUNT_BY_ID';
export const UPDATE_GL_ACCOUNT = 'UPDATE_GL_ACCOUNT';
export const DELETE_GL_ACCOUNT = 'DELETE_GL_ACCOUNT';
export const GET_OFFICE = 'GET_OFFICE';
export const GET_CURRENCY = 'GET_CURRENCY';
export const GET_ACCOUNTING_RULES = 'GET_ACCOUNTING_RULES';
export const GET_INTEREST_CLOSE_ACCOUNT = 'GET_INTEREST_CLOSE_ACCOUNT';
export const GET_TEMPLATE_CLIENT_CLOSE = 'GET_TEMPLATE_CLIENT_CLOSE';
export const SUBMIT_CLOSE_MEMBER = 'SUBMIT_CLOSE_MEMBER';
export const GET_ALL_CODE_TRX_V2 = 'GET_ALL_CODE_TRX_V2';
export const TRANSACTION_BY_COMMAND = 'TRANSACTION_BY_COMMAND';


export const INIT_FETCH_DASHBOARD_LOAN = 'INIT_FETCH_DASHBOARD_LOAN';
export const FETCH_DASHBOARD_LOAN_START = 'FETCH_DASHBOARD_LOAN_START';
export const FETCH_DASHBOARD_LOAN_FAILED = 'FETCH_DASHBOARD_LOAN_FAILED';
export const FETCH_DASHBOARD_LOAN_SUCCESS = 'FETCH_DASHBOARD_LOAN_SUCCESS';
export const GET_MAINTENANCE_SAVINGS = 'GET_MAINTENANCE_SAVINGS';
export const GET_MAINTENANCE_CHARGES_SAVINGS = 'GET_MAINTENANCE_CHARGES_SAVINGS';
export const SUBMIT_MAINTENANCE_SAVINGS = 'SUBMIT_MAINTENANCE_SAVINGS';
export const GET_PARSING_NIK = 'GET_PARSING_NIK';
export const GET_SAVINGPRODUCT_TEMPLATE_BY_ID = 'GET_SAVINGPRODUCT_TEMPLATE_BY_ID';
export const CREATE_CHANGE_PASSWORD_USER_WEB_OTP_REDUX = 'CREATE_CHANGE_PASSWORD_USER_WEB_OTP_REDUX';
export const CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP_REDUX = 'CONFIRM_CHANGE_PASSWORD_USER_WEB_OTP_REDUX';
export const UNLOCK_CONFIRM_MOBILEUSER_BY_ID = 'UNLOCK_CONFIRM_MOBILEUSER_BY_ID';

export const GET_PAYMENTTYPES = 'GET_PAYMENTTYPES';
export const SUBMIT_JURNAL_ENTRIES = 'SUBMIT_JURNAL_ENTRIES';
export const GET_LIST_ACCOUNT_RULES = 'GET_LIST_ACCOUNT_RULES';
export const GET_ACCOUNT_RULES_TEMPLATE = 'GET_ACCOUNT_RULES_TEMPLATE';

export const SUBMIT_ACCOUNTING_RULES = 'SUBMIT_ACCOUNTING_RULES';
export const UPDATE_ACCOUNTING_RULES = 'UPDATE_ACCOUNTING_RULES';
export const GET_ACCOUNTING_RULES_DETAIL = 'GET_ACCOUNTING_RULES_DETAIL';
export const DELETE_ACCOUNTING_RULES = 'DELETE_ACCOUNTING_RULES';
export const GET_ACCOUNTING_RULES_DETAIL_WITH_TEMPLATE = 'GET_ACCOUNTING_RULES_DETAIL_WITH_TEMPLATE';
export const FIXDEPOSIT_BY_ID_AND_COMMAND = 'FIXDEPOSIT_BY_ID_AND_COMMAND';
export const GET_GL_ACCOUNT_IS_GENERAL_TRANS_TRUE = 'GET_GL_ACCOUNT_IS_GENERAL_TRANS_TRUE';
export const GET_FIXED_DEPOSIT_BY_QUERY_PARAM = 'GET_FIXED_DEPOSIT_BY_QUERY_PARAM';
export const SUBMIT_MAINTENANCE_TERM_SAVINGS = 'SUBMIT_MAINTENANCE_TERM_SAVINGS';
export const SUBMIT_ADJUSMENT_TERM_SAVINGS = 'SUBMIT_ADJUSMENT_TERM_SAVINGS';
export const GET_LOAN_TRANS_TEMPLATE_BY_ID_AND_COMMAND = 'GET_LOAN_TRANS_TEMPLATE_BY_ID_AND_COMMAND';
export const SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND = 'SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND';
export const ADD_WEB_MENU_PARAMETER = 'ADD_WEB_MENU_PARAMETER';
export const GET_ALL_WEB_MENU_PARAMETER = 'GET_ALL_WEB_MENU_PARAMETER';
export const GET_DETAIL_WEB_MENU_PARAMETER = 'GET_DETAIL_WEB_MENU_PARAMETER';
export const DELETE_WEB_MENU_PARAMETER = 'DELETE_WEB_MENU_PARAMETER';
export const EDIT_WEB_MENU_PARAMETER = 'EDIT_WEB_MENU_PARAMETER';
export const GET_LIST_ROLES = 'GET_LIST_ROLES';
export const GET_LIST_ROLE_MENU_BY_ROLES_ID = 'GET_LIST_ROLE_MENU_BY_ROLES_ID';
export const ADD_ROLE_MENU = 'ADD_ROLE_MENU';
export const DELETE_ROLE_MENU = 'DELETE_ROLE_MENU';
export const EDIT_ROLE_MENU = 'EDIT_ROLE_MENU';
export const GET_GL_ACCOUNT_BY_QUERYPARAM = 'GET_GL_ACCOUNT_BY_QUERYPARAM';
export const GET_LIST_PAYLATER_BY_QUERYPARAM = 'GET_LIST_PAYLATER_BY_QUERYPARAM';
export const GET_LIST_INTERNAL_USER_BY_PARAM = 'GET_LIST_INTERNAL_USER_BY_PARAM';
export const GET_USER_TEMPLATE = 'GET_USER_TEMPLATE';
export const SUBMIT_INTERNAL_USER = 'SUBMIT_INTERNAL_USER';
export const GET_DETAIL_INTERNAL_USER = 'GET_DETAIL_INTERNAL_USER';
export const UPDATE_INTERNAL_USER = 'UPDATE_INTERNAL_USER';
export const DELETE_INTERNAL_USER = 'DELETE_INTERNAL_USER';
export const GET_OTP_CHANGEPASSWORD_INTERNAL_USER = 'GET_OTP_CHANGEPASSWORD_INTERNAL_USER';
export const SUBMIT_CHANGEPASSWORD_INTERNAL_USER = 'SUBMIT_CHANGEPASSWORD_INTERNAL_USER';
export const GET_OTP_UNLOCK_INTERNAL_USER = 'GET_OTP_UNLOCK_INTERNAL_USER';
export const SUBMIT_UNLOCK_INTERNAL_USER = 'SUBMIT_UNLOCK_INTERNAL_USER';
export const GET_MOBILEUSER_BY_USERNAME = 'GET_MOBILEUSER_BY_USERNAME';
export const GET_OTP_RESET_MOBILEUSER = 'GET_OTP_RESET_MOBILEUSER';
export const SUBMIT_OTP_RESET_MOBILEUSER = 'SUBMIT_OTP_RESET_MOBILEUSER';

export const GET_DASHBOARD_TOTAL_APEX = 'GET_DASHBOARD_TOTAL_APEX';
export const GET_DASHBOARD_MEMBER_APEX = 'GET_DASHBOARD_MEMBER_APEX';
export const GET_DASHBOARD_CITY_APEX = 'GET_DASHBOARD_CITY_APEX';
export const GET_DASHBOARD_CLIENTTENANT_APEX = 'GET_DASHBOARD_CLIENTTENANT_APEX';
export const GET_DASHBOARD_GEOCITY_APEX = 'GET_DASHBOARD_GEOCITY_APEX';
export const GET_DASHBOARD_SYNC = 'GET_DASHBOARD_SYNC';
export const GET_DASHBOARD_ACTIVE_MOBILE_USER = 'GET_DASHBOARD_ACTIVE_MOBILE_USER';
export const GET_CHARGES = 'GET_CHARGES';
export const GET_CHARGES_WITH_QUERYPARAM = 'GET_CHARGES_WITH_QUERYPARAM';
export const DELETE_CHARGES = 'DELETE_CHARGES';
export const SUBMIT_CHARGES = 'SUBMIT_CHARGES';
export const GET_DETAIL_EDIT_CHARGES = 'GET_DETAIL_EDIT_CHARGES';
export const EDIT_CHARGES = 'EDIT_CHARGES';
export const GET_MERCHANT_DETAIL = 'GET_MERCHANT_DETAIL';
export const GET_MERCHANT_PAYMENT = 'GET_MERCHANT_PAYMENT';
export const GET_MERCHANT_QRIS = 'GET_MERCHANT_QRIS';
export const CREATE_MERCHANT_SIMPOOLPAYMENT_QR = 'CREATE_MERCHANT_SIMPOOLPAYMENT_QR';
export const SEARCH_JOURNAL_ENTRIES_BY_PARAM = 'SEARCH_JOURNAL_ENTRIES_BY_PARAM';
export const GET_VALUE_RUNREPORT = 'GET_VALUE_RUNREPORT';
export const GET_DATA_AUTO_SWEEP = 'GET_DATA_AUTO_SWEEP';
export const GET_FIND_ACCOUNTNUMBER = 'GET_FIND_ACCOUNTNUMBER';
export const SUBMIT_ADD_AUTOSWEEP = 'SUBMIT_ADD_AUTOSWEEP';
export const SUBMIT_DELETE_AUTOSWEEP = 'SUBMIT_DELETE_AUTOSWEEP';
export const SUBMIT_EDIT_AUTOSWEEP = 'SUBMIT_EDIT_AUTOSWEEP';
export const GET_DATA_AUTO_SWEEP_GROUP = 'GET_DATA_AUTO_SWEEP_GROUP';
export const SUBMIT_ADD_AUTOSWEEPGROUP = 'SUBMIT_ADD_AUTOSWEEPGROUP';
export const SUBMIT_DELETE_AUTOSWEEPGROUP = 'SUBMIT_DELETE_AUTOSWEEPGROUP';
export const SUBMIT_EDIT_AUTOSWEEPGROUP = 'SUBMIT_EDIT_AUTOSWEEPGROUP';
export const GET_DATA_AUTO_SWEEP_BULK = 'GET_DATA_AUTO_SWEEP_BULK';
export const SUBMIT_AUTO_SWEEP_BULK = 'SUBMIT_AUTO_SWEEP_BULK';
export const UPDATE_AUTO_SWEEP_BULK = 'UPDATE_AUTO_SWEEP_BULK';
export const GET_DETAIL_PAYCHARGE_SAVINGS = 'GET_DETAIL_PAYCHARGE_SAVINGS';
export const SUBMIT_PAYCHARGE_SAVINGS = 'SUBMIT_PAYCHARGE_SAVINGS';
export const GET_GLRECURRING_TEMPLATE = 'GET_GLRECURRING_TEMPLATE';
export const SUBMIT_GLRECURRING = 'SUBMIT_GLRECURRING';
export const EDIT_SAVINGS_ACCOUNT = 'EDIT_SAVINGS_ACCOUNT';
export const SUBMIT_COMMAND_MEMBER_POST = 'SUBMIT_COMMAND_MEMBER_POST';
export const GET_DATA_BLOCK_ACCOUNTS = 'GET_DATA_BLOCK_ACCOUNTS';
export const SUBMIT_ADD_BLOCK_ACCOUNTS = 'SUBMIT_ADD_BLOCK_ACCOUNTS';
export const SUBMIT_EDIT_BLOCK_ACCOUNTS = 'SUBMIT_EDIT_BLOCK_ACCOUNTS';
export const SUBMIT_DELETE_BLOCK_ACCOUNTS = 'SUBMIT_DELETE_BLOCK_ACCOUNTS';
export const GET_LIST_PROMOTION_MANAGEMENT = 'GET_LIST_PROMOTION_MANAGEMENT';
export const GET_DETAIL_PROMOTION_MANAGEMENT = 'GET_DETAIL_PROMOTION_MANAGEMENT';
export const SUBMIT_ADD_PROMOTION_MANAGEMENT = 'SUBMIT_ADD_PROMOTION_MANAGEMENT';
export const SUBMIT_EDIT_PROMOTION_MANAGEMENT = 'SUBMIT_EDIT_PROMOTION_MANAGEMENT';
export const SUBMIT_DELETE_PROMOTION_MANAGEMENT = 'SUBMIT_DELETE_PROMOTION_MANAGEMENT';
export const GET_LIST_DEPOSIT_BILLER_SERVICE = 'GET_LIST_DEPOSIT_BILLER_SERVICE';
export const SUBMIT_ADD_DEPOSIT_BILLER_SERVICE = 'SUBMIT_ADD_DEPOSIT_BILLER_SERVICE';
export const SUBMIT_EDIT_DEPOSIT_BILLER_SERVICE = 'SUBMIT_EDIT_DEPOSIT_BILLER_SERVICE';
export const SUBMIT_DELETE_DEPOSIT_BILLER_SERVICE = 'SUBMIT_DELETE_DEPOSIT_BILLER_SERVICE';
export const GET_LIST_DEPOSIT_BILLER = 'GET_LIST_DEPOSIT_BILLER';
export const SUBMIT_ADD_DEPOSIT_BILLER = 'SUBMIT_ADD_DEPOSIT_BILLER';
export const SUBMIT_CANCEL_DEPOSIT_BILLER = 'SUBMIT_CANCEL_DEPOSIT_BILLER';
export const GET_LIST_DEPOSIT_BILLER_MITRA = 'GET_LIST_DEPOSIT_BILLER_MITRA';
export const GET_DEPOSIT_BILLER_MITRA_BY_TENANT = 'GET_DEPOSIT_BILLER_MITRA_BY_TENANT';
export const GET_TEMPLATE_DEPOSIT_BILLER_MITRA_BY_TENANT = 'GET_TEMPLATE_DEPOSIT_BILLER_MITRA_BY_TENANT';
export const GET_INTERMEDIARY_WITH_QUERYPARAM = 'GET_INTERMEDIARY_WITH_QUERYPARAM';
export const GET_DATA_AGENT_GROUP = 'GET_DATA_AGENT_GROUP';
export const SUBMIT_ADD_COMISSION_AGENT = 'SUBMIT_ADD_COMISSION_AGENT';
export const SUBMIT_DELETE_COMISSION_AGENT = 'SUBMIT_DELETE_COMISSION_AGENT';
export const SUBMIT_ADD_AGENT_GROUP = 'SUBMIT_ADD_AGENT_GROUP';
export const SUBMIT_EDIT_AGENT_GROUP = 'SUBMIT_EDIT_AGENT_GROUP';
export const SUBMIT_DELETE_AGENT_GROUP = 'SUBMIT_DELETE_AGENT_GROUP';
export const SUBMIT_EDIT_COMISSION_AGENT = 'SUBMIT_EDIT_COMISSION_AGENT';
export const GET_DATA_AGENT = 'GET_DATA_AGENT';
export const SUBMIT_ADD_SPECIAL_COMISSION_AGENT = 'SUBMIT_ADD_SPECIAL_COMISSION_AGENT';
export const SUBMIT_EDIT_SPECIAL_COMISSION_AGENT = 'SUBMIT_EDIT_SPECIAL_COMISSION_AGENT';
export const SUBMIT_DELETE_SPECIAL_COMISSION_AGENT = 'SUBMIT_DELETE_SPECIAL_COMISSION_AGENT';
export const SUBMIT_ADD_AGENT = 'SUBMIT_ADD_AGENT';
export const SUBMIT_EDIT_AGENT = 'SUBMIT_EDIT_AGENT';
export const SUBMIT_DELETE_AGENT = 'SUBMIT_DELETE_AGENT';
export const GET_DATA_LOAN_COLLECTION_SHEET = 'GET_DATA_LOAN_COLLECTION_SHEET';
export const SUBMIT_BATCHES = 'SUBMIT_BATCHES';
export const SUBMIT_LOAN_DISBURSEMENT_MULTIPLE = 'SUBMIT_LOAN_DISBURSEMENT_MULTIPLE';
export const GET_LOAN_CHARGES_TEMPLATE = 'GET_LOAN_CHARGES_TEMPLATE';
export const SUBMIT_LOAN_CHARGES = 'SUBMIT_LOAN_CHARGES';
export const GET_LOANS_BY_QUERY_PARAMS = 'GET_LOANS_BY_QUERY_PARAMS';
export const EDIT_CHARGES_IN_LOAN = 'EDIT_CHARGES_IN_LOAN';
export const DELETE_CHARGES_IN_LOAN = 'DELETE_CHARGES_IN_LOAN';
export const GET_DETAIL_CHARGES_IN_LOAN = 'GET_DETAIL_CHARGES_IN_LOAN';
export const SUBMIT_WAIVE_CHARGES_IN_LOAN = 'SUBMIT_WAIVE_CHARGES_IN_LOAN';
export const GET_DATA_OFFICES = 'GET_DATA_OFFICES';
export const SUBMIT_ADD_OFFICES = 'SUBMIT_ADD_OFFICES';
export const SUBMIT_EDIT_OFFICES = 'SUBMIT_EDIT_OFFICES';
export const GET_DATA_EXTERNAL_SERVICE_SMTP = 'GET_DATA_EXTERNAL_SERVICE_SMTP';
export const EDIT_EXTERNAL_SERVICE_SMTP = 'EDIT_EXTERNAL_SERVICE_SMTP';
export const GET_ALL_CODE_TRX_NON_CASH = 'GET_ALL_CODE_TRX_NON_CASH';
export const GET_DATA_REFERRAL_CODE = 'GET_DATA_REFERRAL_CODE';
export const SUBMIT_ADD_REFERRAL_CODE = 'SUBMIT_ADD_REFERRAL_CODE';
export const SUBMIT_DELETE_REFERRAL_CODE = 'SUBMIT_DELETE_REFERRAL_CODE';
export const SUBMIT_EDIT_REFERRAL_CODE = 'SUBMIT_EDIT_REFERRAL_CODE';
export const GET_ACC_TRANSFER_TEMPLATE = 'GET_ACC_TRANSFER_TEMPLATE';
export const SUBMIT_TRANSFER_OVERPAID_LOAN = 'SUBMIT_TRANSFER_OVERPAID_LOAN';
export const GET_LIST_MEMBER = 'GET_LIST_MEMBER';
export const GET_DATA_PASSBOOKTYPE = 'GET_DATA_PASSBOOKTYPE';
export const SUBMIT_ADD_PASSBOOKTYPE = 'SUBMIT_ADD_PASSBOOKTYPE';
export const SUBMIT_DELETE_PASSBOOKTYPE = 'SUBMIT_DELETE_PASSBOOKTYPE';
export const SUBMIT_RESET_PASSBOOK = 'SUBMIT_RESET_PASSBOOK';
export const SUBMIT_PRINT_PASSBOOK = 'SUBMIT_PRINT_PASSBOOK';
export const SUBMIT_PRINT_PASSBOOKCOVER = 'SUBMIT_PRINT_PASSBOOKCOVER';
export const SUBMIT_PASSBOOKCOVER = 'SUBMIT_PASSBOOKCOVER';
export const SUBMIT_PRINT_PASSBOOK_POST = 'SUBMIT_PRINT_PASSBOOK_POST';
export const SUBMIT_RE_PRINT_PASSBOOK = 'SUBMIT_RE_PRINT_PASSBOOK';
export const GET_LIST_DEPOSITBILLER_SETTLEMENT = 'GET_LIST_DEPOSITBILLER_SETTLEMENT';
export const GET_PRODUCT_GROUP_PPOB = 'GET_PRODUCT_GROUP_PPOB';
export const SUBMIT_ADD_LIST_DEPOSIT_BILLER_PHOTO = 'SUBMIT_ADD_LIST_DEPOSIT_BILLER_PHOTO';
export const SUBMIT_DELETE_CORE_TRANSACTION_DETAIL = 'SUBMIT_DELETE_CORE_TRANSACTION_DETAIL';
export const GET_CODE_TRANSACTION_DETAIL = 'GET_CODE_TRANSACTION_DETAIL';
export const SUBMIT_CODE_TRANSACTION_DETAIL = 'SUBMIT_CODE_TRANSACTION_DETAIL';
export const SUBMIT_EDIT_CODE_TRANSACTION_DETAIL = 'SUBMIT_EDIT_CODE_TRANSACTION_DETAIL';
export const SUBMIT_CODE_TRANSACTION_DETAIL_OVERDRAFT = 'SUBMIT_CODE_TRANSACTION_DETAIL_OVERDRAFT';
export const SUBMIT_EDIT_CODE_TRANSACTION_DETAIL_OVERDRAFT = 'SUBMIT_EDIT_CODE_TRANSACTION_DETAIL_OVERDRAFT';
export const GET_TRANSACTION_CODE_LIMIT = 'GET_TRANSACTION_CODE_LIMIT';
export const SUBMIT_ADD_TRANSACTION_CODE_LIMIT = 'SUBMIT_ADD_TRANSACTION_CODE_LIMIT';
export const SUBMIT_DELETE_TRANSACTION_CODE_LIMIT = 'SUBMIT_DELETE_TRANSACTION_CODE_LIMIT';
export const SUBMIT_EDIT_TRANSACTION_CODE_LIMIT = 'SUBMIT_EDIT_TRANSACTION_CODE_LIMIT';
export const GET_DATA_CLIENTJOB = 'GET_DATA_CLIENTJOB';
export const SUBMIT_ADD_CLIENTJOB = 'SUBMIT_ADD_CLIENTJOB';
export const SUBMIT_EDIT_CLIENTJOB = 'SUBMIT_EDIT_CLIENTJOB';
export const SUBMIT_DELETE_CLIENTJOB = 'SUBMIT_DELETE_CLIENTJOB';
export const GET_LIST_TRANSACTION_MODE = 'GET_LIST_TRANSACTION_MODE';
export const SUBMIT_ADD_TRANSACTION_MODE = 'SUBMIT_ADD_TRANSACTION_MODE';
export const SUBMIT_EDIT_TRANSACTION_MODE = 'SUBMIT_EDIT_TRANSACTION_MODE';
export const SUBMIT_DELETE_TRANSACTION_MODE = 'SUBMIT_DELETE_TRANSACTION_MODE';
export const GET_LIST_TRANSACTION_CODE = 'GET_LIST_TRANSACTION_CODE';
export const GET_LIST_CHARGES = 'GET_LIST_CHARGES';
export const SUBMIT_ADD_TRANSACTION_CODE = 'SUBMIT_ADD_TRANSACTION_CODE';
export const SUBMIT_DELETE_TRANSACTION_CODE = 'SUBMIT_DELETE_TRANSACTION_CODE';
export const GET_DATA_TRANSACTION_CODE = 'GET_DATA_TRANSACTION_CODE';
export const GET_DATA_TRANSACTION_MODE = 'GET_DATA_TRANSACTION_MODE';
export const SUBMIT_EDIT_TRANSACTION_CODE = 'SUBMIT_EDIT_TRANSACTION_CODE';
export const GET_OFFICEAPPROVAL_DATA = 'GET_OFFICEAPPROVAL_DATA';
export const SUBMIT_ADD_OFFICEAPPROVAL = 'SUBMIT_ADD_OFFICEAPPROVAL';
export const SUBMIT_DELETE_OFFICEAPPROVAL = 'SUBMIT_DELETE_OFFICEAPPROVAL';
export const SUBMIT_EDIT_OFFICEAPPROVAL = 'SUBMIT_EDIT_OFFICEAPPROVAL';
export const GET_FIXDEPOSITACC_DATA = 'GET_FIXDEPOSITACC_DATA';
export const GET_DASHBOARD_AGE_APEX = 'GET_DASHBOARD_AGE_APEX';
export const GET_DASHBOARD_GENDER_APEX = 'GET_DASHBOARD_GENDER_APEX';
export const GET_DASHBOARD_LOAN_APEX = 'GET_DASHBOARD_LOAN_APEX';
export const GET_DASHBOARD_LOAN_DISBURSE_APEX = 'GET_DASHBOARD_LOAN_DISBURSE_APEX';
export const GET_PRODUCTTEMPLATE_DATA = 'GET_PRODUCTTEMPLATE_DATA';
export const SUBMIT_ADD_PRODUCTTEMPLATE = 'SUBMIT_ADD_PRODUCTTEMPLATE';
export const SUBMIT_EDIT_PRODUCTTEMPLATE = 'SUBMIT_EDIT_PRODUCTTEMPLATE';
export const SUBMIT_TRANSACTIONCODECONTROL = 'SUBMIT_TRANSACTIONCODECONTROL';
export const GET_CLIENT_APPROVAL_DATA = 'GET_CLIENT_APPROVAL_DATA';
export const SUBMIT_CLIENT_APPROVAL = 'SUBMIT_CLIENT_APPROVAL';
export const SUBMIT_CLIENT_APPROVAL_MULTIPLE = 'SUBMIT_CLIENT_APPROVAL_MULTIPLE';
export const GET_CLIENT_APPROVAL_DATA_LIST_ACTIVATION = 'GET_CLIENT_APPROVAL_DATA_LIST_ACTIVATION';
export const GET_FEE_BY_TRX_MODE_CHARGE_PRODUCT = 'GET_FEE_BY_TRX_MODE_CHARGE_PRODUCT';
export const GET_DATA_MEMBER_TEMPLATE = 'GET_DATA_MEMBER_TEMPLATE';
export const GET_DATA_SAVINGS_PRODUCT = 'GET_DATA_SAVINGS_PRODUCT';
export const SUBMIT_UPDATE_SPECIAL_RATE = 'SUBMIT_UPDATE_SPECIAL_RATE';
export const SUBMIT_RESET_SPECIAL_RATE = 'SUBMIT_RESET_SPECIAL_RATE';
export const GET_DATA_TYPE_PASSBOOK = 'GET_DATA_TYPE_PASSBOOK';
export const CHECK_DUPLICATE_MEMBER = 'CHECK_DUPLICATE_MEMBER';
export const SUBMIT_ADD_ENTITY_MEMBER = 'SUBMIT_ADD_ENTITY_MEMBER';
export const GET_DATA_ENTITY_MEMBER = 'GET_DATA_ENTITY_MEMBER';
export const GET_DATA_PERSON_MEMBER = 'GET_DATA_PERSON_MEMBER';
export const GET_DATA_MEMBER_WITH_PARAM = 'GET_DATA_MEMBER_WITH_PARAM';
export const SUBMIT_EDIT_ENTITY_MEMBER = 'SUBMIT_EDIT_ENTITY_MEMBER';
export const GET_DATA_UNIT_USER = 'GET_DATA_UNIT_USER';
export const SUBMIT_ADD_UNIT_USER = 'SUBMIT_ADD_UNIT_USER';
export const SUBMIT_EDIT_UNIT_USER = 'SUBMIT_EDIT_UNIT_USER';
export const SUBMIT_DELETE_UNIT_USER = 'SUBMIT_DELETE_UNIT_USER';
export const SUBMIT_ADD_SAVINGS_PRODUCT = 'SUBMIT_ADD_SAVINGS_PRODUCT';
export const SUBMIT_EDIT_SAVINGS_PRODUCT = 'SUBMIT_EDIT_SAVINGS_PRODUCT';
export const GET_LOANS_PARAM_DATA_IMPORT = 'GET_LOANS_PARAM_DATA_IMPORT';
export const GET_FUNDS_WITH_PARAM = 'GET_FUNDS_WITH_PARAM';
export const GET_CURRENCY_WITH_PARAM = 'GET_CURRENCY_WITH_PARAM';
export const SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_MULTIPLE = 'SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_MULTIPLE';
export const SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_DATA_IMPORT = 'SUBMIT_LOAN_TRANS_BY_ID_AND_COMMAND_DATA_IMPORT';
export const SUBMIT_UPDATE_EMPLOYEE_INFORMATION_DATA_IMPORT = 'SUBMIT_UPDATE_EMPLOYEE_INFORMATION_DATA_IMPORT';
export const GET_LOANS_DATA_PARAM = 'GET_LOANS_DATA_PARAM';
export const GET_DATA_PAYLATER = 'GET_DATA_PAYLATER';
export const SUBMIT_DATA_IMPORT_PAYLATER = 'SUBMIT_DATA_IMPORT_PAYLATER';
export const GET_LIST_USER_RESET_PASSWORD_MOBILE = 'GET_LIST_USER_RESET_PASSWORD_MOBILE';
export const SUBMIT_LIST_USER_RESET_PASSWORD_MOBILE = 'SUBMIT_LIST_USER_RESET_PASSWORD_MOBILE';
export const SUBMIT_EDIT_LOANS = 'SUBMIT_EDIT_LOANS';
export const SUBMIT_SAVING_TRANSACTION = 'SUBMIT_SAVING_TRANSACTION';
export const GET_DATA_LOAN_PASSBOOKTYPE = 'GET_DATA_LOAN_PASSBOOKTYPE';
export const SUBMIT_ADD_LOAN_PASSBOOKTYPE = 'SUBMIT_ADD_LOAN_PASSBOOKTYPE';
export const SUBMIT_DELETE_LOAN_PASSBOOKTYPE = 'SUBMIT_DELETE_LOAN_PASSBOOKTYPE';
export const GET_FIND_LOAN_ACCNUMBER = 'GET_FIND_LOAN_ACCNUMBER';
export const LOAN_PASSBOOK_COVER = 'LOAN_PASSBOOK_COVER';
export const SUBMIT_LOAN_PASSBOOK_COVER = 'SUBMIT_LOAN_PASSBOOK_COVER';
export const GET_LOAN_PRINT_PASSBOOK = 'GET_LOAN_PRINT_PASSBOOK';
export const SUBMIT_LOAN_PASSBOOK_RESET = 'SUBMIT_LOAN_PASSBOOK_RESET';
export const SUBMIT_LOAN_REPRINT_PASSBOOK = 'SUBMIT_LOAN_REPRINT_PASSBOOK';
export const GET_SAVINGS_ACCOUNT_PARAM = 'GET_SAVINGS_ACCOUNT_PARAM';
export const SUBMIT_EDIT_LOAN_PASSBOOKTYPE = 'SUBMIT_EDIT_LOAN_PASSBOOKTYPE';
export const GET_DATA_EMPLOYMENT_LEVEL = 'GET_DATA_EMPLOYMENT_LEVEL';
export const SUBMIT_ADD_EMPLOYMENT_LEVEL = 'SUBMIT_ADD_EMPLOYMENT_LEVEL';
export const SUBMIT_EDIT_EMPLOYMENT_LEVEL = 'SUBMIT_EDIT_EMPLOYMENT_LEVEL';
export const SUBMIT_DELETE_EMPLOYMENT_LEVEL = 'SUBMIT_DELETE_EMPLOYMENT_LEVEL';
export const GET_SAVING_TEMPLATE_PAYLATER = 'GET_SAVING_TEMPLATE_PAYLATER';
export const EXTERNAL_SERVICE_CHAT_BOT = 'EXTERNAL_SERVICE_CHAT_BOT';
export const GET_TOKEN_ROOC = 'GET_TOKEN_ROOC';
export const SUBMIT_LOCK_INTERNAL_USER = 'SUBMIT_LOCK_INTERNAL_USER';
export const GET_LIST_SAVINGS_ACCOUNT_PARAM = 'GET_LIST_SAVINGS_ACCOUNT_PARAM';
export const GET_DATA_MEMBER = 'GET_DATA_MEMBER';
export const GET_DATA_TRANSACTION_LIMIT = 'GET_DATA_TRANSACTION_LIMIT';
export const SUBMIT_ADD_TRANSACTION_LIMIT = 'SUBMIT_ADD_TRANSACTION_LIMIT';
export const SUBMIT_DATA_IMPORT_UPLOAD_CLIENT = 'SUBMIT_DATA_IMPORT_UPLOAD_CLIENT';
export const GET_ALL_CODE_TRX_CASH = 'GET_ALL_CODE_TRX_CASH';
export const GET_DATA_TELLER_LIMIT = 'GET_DATA_TELLER_LIMIT';
export const SUBMIT_ADD_TELLER_LIMIT = 'SUBMIT_ADD_TELLER_LIMIT';
export const SUBMIT_EDIT_TELLER_LIMIT = 'SUBMIT_EDIT_TELLER_LIMIT';
export const SUBMIT_DELETE_TELLER_LIMIT = 'SUBMIT_DELETE_TELLER_LIMIT';
export const GET_DATA_DENOMINATION = 'GET_DATA_DENOMINATION';
export const GET_DATA_FINANCIALACTIVITYACCOUNT = 'GET_DATA_FINANCIALACTIVITYACCOUNT';
export const SUBMIT_EDIT_TRANSACTION_LIMIT = 'SUBMIT_EDIT_TRANSACTION_LIMIT';
export const SUBMIT_DELETE_TRANSACTION_LIMIT = 'SUBMIT_DELETE_TRANSACTION_LIMIT';
export const SUBMIT_ADD_DENOMiNATION = 'SUBMIT_ADD_DENOMiNATION';
export const SUBMIT_EDIT_DENOMiNATION = 'SUBMIT_EDIT_DENOMiNATION';
export const SUBMIT_DELETE_DENOMiNATION = 'SUBMIT_DELETE_DENOMiNATION';
export const GET_DATA_MAKERCHEKER = 'GET_DATA_MAKERCHEKER';
export const SUBMIT_MAKER_CHEKER_MULTIPLE = 'SUBMIT_MAKER_CHEKER_MULTIPLE';
export const GET_DATA_AUDITS = 'GET_DATA_AUDITS';
export const SUBMIT_DELETE_MAKERCHEKER = 'SUBMIT_DELETE_MAKERCHEKER';
export const GET_DATA_TELLER_DASHBOARD = 'GET_DATA_TELLER_DASHBOARD';
export const SUBMIT_ADD_TELLER_OPERATION = 'SUBMIT_ADD_TELLER_OPERATION';
export const SUBMIT_EDIT_TELLER_OPERATION = 'SUBMIT_EDIT_TELLER_OPERATION';
export const SUBMIT_DELETE_TELLER_OPERATION = 'SUBMIT_DELETE_TELLER_OPERATION';
export const SUBMIT_SETTLE_TELLER = 'SUBMIT_SETTLE_TELLER';
export const SUBMIT_ALLOCATE_TELLER = 'SUBMIT_ALLOCATE_TELLER';
export const GET_QUICK_SEARCH = 'GET_QUICK_SEARCH';
export const GET_RUN_REPORT_CHECK = 'GET_RUN_REPORT_CHECK';
export const GET_GL_SUMMARY_PARAMETER_DATA = 'GET_GL_SUMMARY_PARAMETER_DATA';
export const SUBMIT_ADD_GL_SUMMARY_PARAMETER = 'SUBMIT_ADD_GL_SUMMARY_PARAMETER';
export const SUBMIT_EDIT_GL_SUMMARY_PARAMETER = 'SUBMIT_EDIT_GL_SUMMARY_PARAMETER';
export const SUBMIT_DELETE_GL_SUMMARY_PARAMETER = 'SUBMIT_DELETE_GL_SUMMARY_PARAMETER';
export const GET_PRINT_PASSBOOK = 'GET_PRINT_PASSBOOK';
export const GET_EXTERNAL_USER_PAGING = 'GET_EXTERNAL_USER_PAGING';
export const GET_INTERNAL_USER_PAGING = 'GET_INTERNAL_USER_PAGING';
export const GET_LOANS_PAGING = 'GET_LOANS_PAGING';
export const GET_TERMSAVING_PAGING = 'GET_TERMSAVING_PAGING';
export const GET_SUBMISSION_LOAN = 'GET_SUBMISSION_LOAN';
export const GET_NOTES_LOAN = 'GET_NOTES_LOAN';
export const SUBMIT_NOTES_LOAN = 'SUBMIT_NOTES_LOAN';
export const GET_LIST_DOCUMENT_LOAN = 'GET_LIST_DOCUMENT_LOAN';
export const DOWNLOAD_DOCUMENT_LOAN = 'DOWNLOAD_DOCUMENT_LOAN';
export const DELETE_DOCUMENT_LOAN = 'DELETE_DOCUMENT_LOAN';
export const SUBMIT_ADD_DOCUMENT_LOAN = 'SUBMIT_ADD_DOCUMENT_LOAN';
export const GET_REPORT_JOB = 'GET_REPORT_JOB';
export const GET_PRODUCTLOAN_DATA = 'GET_PRODUCTLOAN_DATA';
export const SUBMIT_ADD_PRODUCTLOAN = 'SUBMIT_ADD_PRODUCTLOAN';
export const SUBMIT_EDIT_PRODUCTLOAN = 'SUBMIT_EDIT_PRODUCTLOAN';
export const GET_DEPOSIT_RECURRING_DATA = 'GET_DEPOSIT_RECURRING_DATA';
export const GET_SAVING_DEPOSIT_RECURRING_DATA = 'GET_SAVING_DEPOSIT_RECURRING_DATA';
export const GET_ACCOUNT_TRANSFER_DATA = 'GET_ACCOUNT_TRANSFER_DATA';
export const SUBMIT_DEPOSIT_RECURRING = 'SUBMIT_DEPOSIT_RECURRING';
export const SUBMIT_INACTIVE_LOAN_CHARGES = 'SUBMIT_INACTIVE_LOAN_CHARGES';
export const SUBMIT_PRINT_PASSBOOK_PARAM = 'SUBMIT_PRINT_PASSBOOK_PARAM';
export const GET_DATA_TELLER_DASHBOARD_PARAM = 'GET_DATA_TELLER_DASHBOARD_PARAM';
export const GET_FIX_DEPOSIT_PARAM = 'GET_FIX_DEPOSIT_PARAM';
export const SUBMIT_POST_FIX_DEPOSIT_PARAM = 'SUBMIT_POST_FIX_DEPOSIT_PARAM';
export const GET_LIST_TRANSACTION_HISTORY_PARAM = 'GET_LIST_TRANSACTION_HISTORY_PARAM';
export const GET_LIST_TRANSACTION_HISTORY_TOP_UP_PARAM = 'GET_LIST_TRANSACTION_HISTORY_TOP_UP_PARAM';
export const GET_MERCHANT_TEMPLATE = 'GET_MERCHANT_TEMPLATE';
export const GET_MERCHANT_SAVING = 'GET_MERCHANT_SAVING';
export const SUBMIT_ADD_MERCHANT = 'SUBMIT_ADD_MERCHANT';
export const GET_DATA_MERCHANT = 'GET_DATA_MERCHANT';
export const SUBMIT_EDIT_MERCHANT = 'SUBMIT_EDIT_MERCHANT';
export const SUBMIT_REJECT_MERCHANT = 'SUBMIT_REJECT_MERCHANT';
export const SUBMIT_APPROVE_MERCHANT = 'SUBMIT_APPROVE_MERCHANT';
export const SUBMIT_ADD_RECREATE_MERCHANT = 'SUBMIT_ADD_RECREATE_MERCHANT';
export const DATA_STATUS_MERCHANT = 'DATA_STATUS_MERCHANT';
export const GET_FIND_SAVINGRECURRING = 'GET_FIND_SAVINGRECURRING';
export const EDIT_DEPOSIT_RECURRING = 'EDIT_DEPOSIT_RECURRING';
export const GET_DATA_PROVOSIONING_CRITERIA = 'GET_DATA_PROVOSIONING_CRITERIA';
export const SUBMIT_PROVOSIONING_CRITERIA = 'SUBMIT_PROVOSIONING_CRITERIA';
export const GET_SAVINGS_CHARGES = 'GET_SAVINGS_CHARGES';
export const SUBMIT_SAVINGS_CHARGES = 'SUBMIT_SAVINGS_CHARGES';
export const SUBMIT_MAINTENANCE_SAVINGS_CHARGES = 'SUBMIT_MAINTENANCE_SAVINGS_CHARGES';
export const GET_TOKEN_DATA = 'GET_TOKEN_DATA';
export const SUBMIT_TOKEN = 'SUBMIT_TOKEN';
export const SUBMIT_COMMAND_MEMBER_POST_MULTIPLE = 'SUBMIT_COMMAND_MEMBER_POST_MULTIPLE';
export const GET_INTERBRANCH_SETTLEMENT_DATA = 'GET_INTERBRANCH_SETTLEMENT_DATA';
export const SUBMIT_INTERBRANCH_SETTLEMENT = 'SUBMIT_INTERBRANCH_SETTLEMENT';
export const GET_MOBILE_MENU_DATA = 'GET_MOBILE_MENU_DATA';
export const SUBMIT_MOBILE_MENU = 'SUBMIT_MOBILE_MENU';
export const GET_MOBILE_VERSION_DATA = 'GET_MOBILE_VERSION_DATA';
export const SUBMIT_MOBILE_VERSION_DATA = 'SUBMIT_MOBILE_VERSION_DATA';
export const GET_MOBILE_COMMERCE_DATA = 'GET_MOBILE_COMMERCE_DATA';
export const SUBMIT_MOBILE_COMMERCE = 'SUBMIT_MOBILE_COMMERCE';
export const GET_JOBS = 'GET_JOBS';
export const GET_JOBS_PAGING = 'GET_JOBS_PAGING';
export const GET_LOANS = 'GET_LOANS';
export const SUBMIT_LOANS = 'SUBMIT_LOANS';
export const GET_OFFICEAPPROVAL_LOAN = 'GET_OFFICEAPPROVAL_LOAN';
export const SUBMIT_OFFICEAPPROVAL_LOAN = 'SUBMIT_OFFICEAPPROVAL_LOAN';
export const GET_USER_GROUP = 'GET_USER_GROUP';
export const SUBMIT_USER_GROUP = 'SUBMIT_USER_GROUP';
export const GET_PRODUCT_GROUP = 'GET_PRODUCT_GROUP';
export const SUBMIT_PRODUCT_GROUP = 'SUBMIT_PRODUCT_GROUP';
export const SUBMIT_PRODUCT_GROUP_DETAILS = 'SUBMIT_PRODUCT_GROUP_DETAILS';
export const GET_TRANSFER_CLIENT_HISTORY = 'GET_TRANSFER_CLIENT_HISTORY';
export const GET_ORGANISATION_POSITION = 'GET_ORGANISATION_POSITION';
export const SUBMIT_ORGANISATION_POSITION = 'SUBMIT_ORGANISATION_POSITION';
export const GET_ORGANISATION_STRUCTURE = 'GET_ORGANISATION_STRUCTURE';
export const SUBMIT_ORGANISATION_STRUCTURE = 'SUBMIT_ORGANISATION_STRUCTURE';
export const GET_DATA_COLLECTION_SHEET_PAGING = 'GET_DATA_COLLECTION_SHEET_PAGING';
export const GET_DATA_COLLECTION_SHEET = 'GET_DATA_COLLECTION_SHEET';

import axios        from '../../Axios-Simpool';
import {
    getOfficeUrl,getCurrencyUrl,getGlAccountByQueryParam,chargesURL,chargesWithQueryParamURL,runreports,
    submitBatchesURL,getAllCodeTrxNonCashURL,baseppobproductgroupURL,fundsWithParamURL,currencyWithParamURL,getAllCodeTrxCashURL,
    denominationUrl,financialactivityaccountsUrl,makercheckersUrl,auditsUrl,tellerDashboardUrl,transactionsUrl
}                   from '../../shared/apiUrl';

import {toLogout,msgErrorHandlerMapping,msgErrorHandler} from '../../shared/maskFunc';

export function* getOfficeSaga(action) {    
    try {
        const response = yield axios.get(getOfficeUrl).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getCurrencySaga(action) {    
    try {
        const response = yield axios.get(getCurrencyUrl).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getGlAccountByQueryParamSaga(action) {    
    try {
        const response = yield axios.get(getGlAccountByQueryParam(action.queryparam)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getChargeSaga(action) {    
    try {
        const response = yield axios.get(chargesURL).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}
export function* getValueRunReportsSaga(action) {
    try {
        let urlrep = runreports+"/"+action.queryparam;
        const response = yield axios.get(urlrep).then(res => res.data);
        action.successHandler(response);
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getChargeWithQueryParamSaga(action) {    
    try {
        const response = yield axios.get(chargesWithQueryParamURL(action.queryparam)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* deleteChargeSaga(action) {    
    try {
        const response = yield axios.delete(chargesWithQueryParamURL(action.queryparam)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitChargeSaga(action) {    
    try {
        const response = yield axios.post(chargesURL,action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandlerMapping(error);//yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getChargeEditWithQueryParamSaga(action) {    
    try {
        const response = yield axios.get(chargesWithQueryParamURL(action.queryparam)).then(res => res.data);
        action.successHandler(response,action.data);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* editChargeSaga(action) {    
    try {
        const response = yield axios.put(chargesWithQueryParamURL('/'+action.id),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitBatchesSaga(action) {    
    try {
        const response = yield axios.post(submitBatchesURL,action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getAllCodeTrxNonCashSaga(action) {    
    try {
        const response = yield axios.get(getAllCodeTrxNonCashURL).then(res => res.data);
        let obj = new Object();
        obj.pageItems = response;
        action.successHandler(obj);
        
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getProductGroupPPOBSaga(action) {    
    try {
        const response = yield axios.get(baseppobproductgroupURL).then(res => res.data);
        action.successHandler(response);
        
    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getFundsWithParamSaga(action) {    
    try {
        const response = yield axios.get(fundsWithParamURL(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getCurrencyWithParamSaga(action) {    
    try {
        const response = yield axios.get(currencyWithParamURL(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getAllCodeTrxCashSaga(action) {    
    try {
        const response = yield axios.get(getAllCodeTrxCashURL).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataDenominationSaga(action) {    
    try {
        const response = yield axios.get(denominationUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataFinancialActivitySaga(action) {    
    try {
        const response = yield axios.get(financialactivityaccountsUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitAddDenominationSaga(action) {    
    try {
        const response = yield axios.post(denominationUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitEditDenominationSaga(action) {    
    try {
        const response = yield axios.put(denominationUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteDenominationSaga(action) {    
    try {
        const response = yield axios.delete(denominationUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataMakerChekerSaga(action) {    
    try {
        const response = yield axios.get(makercheckersUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitMakerChekerMultipleSaga(action) {
    try {
        const response = yield axios.post(makercheckersUrl(action.param),action.payload).then(response => response.data);
        action.successHandler(response,action.listid);
    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages,action.listid);
    }
}

export function* getDataAuditsSaga(action) {    
    try {
        const response = yield axios.get(auditsUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteMakerChekeSaga(action) {    
    try {
        const response = yield axios.delete(makercheckersUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        action.errorHandler(errMessages);
    }
}

export function* getDataTellerDashboardSaga(action) {    
    try {
        const response = yield axios.get(tellerDashboardUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitAddTellerOperationSaga(action) {    
    try {
        const response = yield axios.post(tellerDashboardUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitEditTellerOperationSaga(action) {    
    try {
        const response = yield axios.put(tellerDashboardUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitDeleteTellerOperationSaga(action) {    
    try {
        const response = yield axios.delete(tellerDashboardUrl(action.param)).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitSettleTellerSaga(action) {    
    try {
        const response = yield axios.post(tellerDashboardUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* submitAllocateTellerSaga(action) {    
    try {
        const response = yield axios.post(tellerDashboardUrl(action.param),action.payload).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* getDataQuickSearchSaga(action) {    
    try {
        const response = yield axios.get(transactionsUrl+action.param).then(res => res.data);
        action.successHandler(response);

    } catch (error) {
        toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}

export function* getRunreportCheckSaga(action) {    
    try {
        const response = yield axios.get(runreports+'/check/'+action.encodenamafile).then(res => res.data);
        action.successHandler(response,action.namafile);

    } catch (error) {
        toLogout(error);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}


export function* getDataTellerDashboardParamSaga(action) {    
    try {
        const response = yield axios.get(tellerDashboardUrl(action.queryparam)).then(res => res.data);
        action.successHandler(response,action.actiontype);

    } catch (error) {
        // toLogout(error);
        // const errMessages = yield error.data.errors.reduce((obj, el) => [...obj, el.defaultUserMessage], []);
        const errMessages = msgErrorHandler(error);
        action.errorHandler(errMessages);
    }
}